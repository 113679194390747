import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 600,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      
    },

    
    DataItems: [
      { CDATE: '2024-10-04', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'Hull',    PE_WK: 0.1, PL_WK: 0,   AC_WK: 0.1,  DE_WK: 0,    DL_WK: 0.1,  PE_CUM: 0.1,  PL_CUM: 0,    AC_CUM: 0.1, DE_CUM: 0,     DL_CUM: 0.1, },
      { CDATE: '2024-11-15', AREA: 'Hull',    PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0.2,  DE_WK: 0,    DL_WK: 0.1,  PE_CUM: 0.2,  PL_CUM: 0.1,  AC_CUM: 0.3, DE_CUM: 0,     DL_CUM: 0.2, },
      { CDATE: '2024-11-22', AREA: 'Hull',    PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0.5,  DE_WK: 0.3,  DL_WK: 0.3,  PE_CUM: 0.4,  PL_CUM: 0.2,  AC_CUM: 0.7, DE_CUM: 0.3,   DL_CUM: 0.5, },
      { CDATE: '2024-11-29', AREA: 'Hull',    PE_WK: 0.2, PL_WK: 0.1, AC_WK: -0.7, DE_WK: -1,   DL_WK: -0.9, PE_CUM: 0.7,  PL_CUM: 0.3,  AC_CUM: 0,   DE_CUM: -0.7,  DL_CUM: -0.3, },
      { CDATE: '2024-12-06', AREA: 'Hull',    PE_WK: 0.4, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.2, PE_CUM: 1,    PL_CUM: 0.5,  AC_CUM: 0,   DE_CUM: -1,    DL_CUM: -0.5, },
      { CDATE: '2024-12-13', AREA: 'Hull',    PE_WK: 0.6, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.3, PE_CUM: 1.6,  PL_CUM: 0.8,  AC_CUM: 0,   DE_CUM: -1.6,  DL_CUM: -0.8, },
      { CDATE: '2024-12-20', AREA: 'Hull',    PE_WK: 0.7, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.5, PE_CUM: 2.4,  PL_CUM: 1.3,  AC_CUM: 0,   DE_CUM: -2.4,  DL_CUM: -1.3, },
      { CDATE: '2024-12-27', AREA: 'Hull',    PE_WK: 0.7, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.6, PE_CUM: 3.1,  PL_CUM: 1.9,  AC_CUM: 0,   DE_CUM: -3.1,  DL_CUM: -1.9, },
      { CDATE: '2025-01-03', AREA: 'Hull',    PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 3.7,  PL_CUM: 2.5,  AC_CUM: 0,   DE_CUM: -3.7,  DL_CUM: -2.5, },
      { CDATE: '2025-01-10', AREA: 'Hull',    PE_WK: 0.8, PL_WK: 0.7, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.7, PE_CUM: 4.5,  PL_CUM: 3.2,  AC_CUM: 0,   DE_CUM: -4.5,  DL_CUM: -3.2, },
      { CDATE: '2025-01-17', AREA: 'Hull',    PE_WK: 0.8, PL_WK: 0.8, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.8, PE_CUM: 5.3,  PL_CUM: 3.9,  AC_CUM: 0,   DE_CUM: -5.3,  DL_CUM: -3.9, },
      { CDATE: '2025-01-24', AREA: 'Hull',    PE_WK: 0.9, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.9, DL_WK: -0.9, PE_CUM: 6.3,  PL_CUM: 4.8,  AC_CUM: 0,   DE_CUM: -6.3,  DL_CUM: -4.8, },
      { CDATE: '2025-01-31', AREA: 'Hull',    PE_WK: 0.3, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.3, PE_CUM: 6.5,  PL_CUM: 5.1,  AC_CUM: 0,   DE_CUM: -6.5,  DL_CUM: -5.1, },
      { CDATE: '2025-02-07', AREA: 'Hull',    PE_WK: 0.8, PL_WK: 0.7, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.7, PE_CUM: 7.3,  PL_CUM: 5.8,  AC_CUM: 0,   DE_CUM: -7.3,  DL_CUM: -5.8, },
      { CDATE: '2025-02-14', AREA: 'Hull',    PE_WK: 1.2, PL_WK: 1,   AC_WK: 0,    DE_WK: -1.2, DL_WK: -1,   PE_CUM: 8.5,  PL_CUM: 6.8,  AC_CUM: 0,   DE_CUM: -8.5,  DL_CUM: -6.8, },
      { CDATE: '2025-02-21', AREA: 'Hull',    PE_WK: 1.4, PL_WK: 1.1, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.1, PE_CUM: 9.9,  PL_CUM: 8,    AC_CUM: 0,   DE_CUM: -9.9,  DL_CUM: -8, },
      { CDATE: '2025-02-28', AREA: 'Hull',    PE_WK: 1.7, PL_WK: 1.2, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.2, PE_CUM: 11.6, PL_CUM: 9.2,  AC_CUM: 0,   DE_CUM: -11.6, DL_CUM: -9.2, },
      { CDATE: '2025-03-07', AREA: 'Hull',    PE_WK: 2,   PL_WK: 1.4, AC_WK: 0,    DE_WK: -2,   DL_WK: -1.4, PE_CUM: 13.6, PL_CUM: 10.6, AC_CUM: 0,   DE_CUM: -13.6, DL_CUM: -10.6, },
      { CDATE: '2025-03-14', AREA: 'Hull',    PE_WK: 2.1, PL_WK: 1.6, AC_WK: 0,    DE_WK: -2.1, DL_WK: -1.6, PE_CUM: 15.6, PL_CUM: 12.3, AC_CUM: 0,   DE_CUM: -15.6, DL_CUM: -12.3, },
      { CDATE: '2025-03-21', AREA: 'Hull',    PE_WK: 2.5, PL_WK: 1.7, AC_WK: 0,    DE_WK: -2.5, DL_WK: -1.7, PE_CUM: 18.2, PL_CUM: 13.9, AC_CUM: 0,   DE_CUM: -18.2, DL_CUM: -13.9, },
      { CDATE: '2025-03-28', AREA: 'Hull',    PE_WK: 3,   PL_WK: 1.9, AC_WK: 0,    DE_WK: -3,   DL_WK: -1.9, PE_CUM: 21.2, PL_CUM: 15.8, AC_CUM: 0,   DE_CUM: -21.2, DL_CUM: -15.8, },
      { CDATE: '2025-04-04', AREA: 'Hull',    PE_WK: 2,   PL_WK: 1.8, AC_WK: 0,    DE_WK: -2,   DL_WK: -1.8, PE_CUM: 23.1, PL_CUM: 17.6, AC_CUM: 0,   DE_CUM: -23.1, DL_CUM: -17.6, },
      { CDATE: '2025-04-11', AREA: 'Hull',    PE_WK: 3.2, PL_WK: 2.6, AC_WK: 0,    DE_WK: -3.2, DL_WK: -2.6, PE_CUM: 26.4, PL_CUM: 20.1, AC_CUM: 0,   DE_CUM: -26.4, DL_CUM: -20.1, },
      { CDATE: '2025-04-18', AREA: 'Hull',    PE_WK: 3.4, PL_WK: 3.1, AC_WK: 0,    DE_WK: -3.4, DL_WK: -3.1, PE_CUM: 29.7, PL_CUM: 23.3, AC_CUM: 0,   DE_CUM: -29.7, DL_CUM: -23.3, },
      { CDATE: '2025-04-25', AREA: 'Hull',    PE_WK: 2.6, PL_WK: 3.7, AC_WK: 0,    DE_WK: -2.6, DL_WK: -3.7, PE_CUM: 32.3, PL_CUM: 27,   AC_CUM: 0,   DE_CUM: -32.3, DL_CUM: -27, },
      { CDATE: '2025-05-02', AREA: 'Hull',    PE_WK: 2.1, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.1, DL_WK: -2.3, PE_CUM: 34.5, PL_CUM: 29.2, AC_CUM: 0,   DE_CUM: -34.5, DL_CUM: -29.2, },
      { CDATE: '2025-05-09', AREA: 'Hull',    PE_WK: 2.4, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.4, DL_WK: -2.3, PE_CUM: 36.9, PL_CUM: 31.6, AC_CUM: 0,   DE_CUM: -36.9, DL_CUM: -31.6, },
      { CDATE: '2025-05-16', AREA: 'Hull',    PE_WK: 2.8, PL_WK: 2.7, AC_WK: 0,    DE_WK: -2.8, DL_WK: -2.7, PE_CUM: 39.7, PL_CUM: 34.3, AC_CUM: 0,   DE_CUM: -39.7, DL_CUM: -34.3, },
      { CDATE: '2025-05-23', AREA: 'Hull',    PE_WK: 3.1, PL_WK: 2.7, AC_WK: 0,    DE_WK: -3.1, DL_WK: -2.7, PE_CUM: 42.8, PL_CUM: 37,   AC_CUM: 0,   DE_CUM: -42.8, DL_CUM: -37, },
      { CDATE: '2025-05-30', AREA: 'Hull',    PE_WK: 2.5, PL_WK: 2.7, AC_WK: 0,    DE_WK: -2.5, DL_WK: -2.7, PE_CUM: 45.2, PL_CUM: 39.7, AC_CUM: 0,   DE_CUM: -45.2, DL_CUM: -39.7, },
      { CDATE: '2025-06-06', AREA: 'Hull',    PE_WK: 1.4, PL_WK: 1.7, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.7, PE_CUM: 46.6, PL_CUM: 41.3, AC_CUM: 0,   DE_CUM: -46.6, DL_CUM: -41.3, },
      { CDATE: '2025-06-13', AREA: 'Hull',    PE_WK: 2.4, PL_WK: 1.8, AC_WK: 0,    DE_WK: -2.4, DL_WK: -1.8, PE_CUM: 49,   PL_CUM: 43.2, AC_CUM: 0,   DE_CUM: -49,   DL_CUM: -43.2, },
      { CDATE: '2025-06-20', AREA: 'Hull',    PE_WK: 2.9, PL_WK: 2.1, AC_WK: 0,    DE_WK: -2.9, DL_WK: -2.1, PE_CUM: 52,   PL_CUM: 45.3, AC_CUM: 0,   DE_CUM: -52,   DL_CUM: -45.3, },
      { CDATE: '2025-06-27', AREA: 'Hull',    PE_WK: 3.1, PL_WK: 2.7, AC_WK: 0,    DE_WK: -3.1, DL_WK: -2.7, PE_CUM: 55.1, PL_CUM: 48,   AC_CUM: 0,   DE_CUM: -55.1, DL_CUM: -48, },
      { CDATE: '2025-07-04', AREA: 'Hull',    PE_WK: 2.4, PL_WK: 3.1, AC_WK: 0,    DE_WK: -2.4, DL_WK: -3.1, PE_CUM: 57.5, PL_CUM: 51.1, AC_CUM: 0,   DE_CUM: -57.5, DL_CUM: -51.1, },
      { CDATE: '2025-07-11', AREA: 'Hull',    PE_WK: 2.8, PL_WK: 2.8, AC_WK: 0,    DE_WK: -2.8, DL_WK: -2.8, PE_CUM: 60.3, PL_CUM: 53.9, AC_CUM: 0,   DE_CUM: -60.3, DL_CUM: -53.9, },
      { CDATE: '2025-07-18', AREA: 'Hull',    PE_WK: 3,   PL_WK: 2.7, AC_WK: 0,    DE_WK: -3,   DL_WK: -2.7, PE_CUM: 63.2, PL_CUM: 56.6, AC_CUM: 0,   DE_CUM: -63.2, DL_CUM: -56.6, },
      { CDATE: '2025-07-25', AREA: 'Hull',    PE_WK: 2.5, PL_WK: 2.7, AC_WK: 0,    DE_WK: -2.5, DL_WK: -2.7, PE_CUM: 65.8, PL_CUM: 59.3, AC_CUM: 0,   DE_CUM: -65.8, DL_CUM: -59.3, },
      { CDATE: '2025-08-01', AREA: 'Hull',    PE_WK: 0.9, PL_WK: 1.1, AC_WK: 0,    DE_WK: -0.9, DL_WK: -1.1, PE_CUM: 66.6, PL_CUM: 60.5, AC_CUM: 0,   DE_CUM: -66.6, DL_CUM: -60.5, },
      { CDATE: '2025-08-08', AREA: 'Hull',    PE_WK: 0.8, PL_WK: 1.5, AC_WK: 0,    DE_WK: -0.8, DL_WK: -1.5, PE_CUM: 67.4, PL_CUM: 62,   AC_CUM: 0,   DE_CUM: -67.4, DL_CUM: -62, },
      { CDATE: '2025-08-15', AREA: 'Hull',    PE_WK: 1.4, PL_WK: 2.2, AC_WK: 0,    DE_WK: -1.4, DL_WK: -2.2, PE_CUM: 68.8, PL_CUM: 64.2, AC_CUM: 0,   DE_CUM: -68.8, DL_CUM: -64.2, },
      { CDATE: '2025-08-22', AREA: 'Hull',    PE_WK: 2.2, PL_WK: 2,   AC_WK: 0,    DE_WK: -2.2, DL_WK: -2,   PE_CUM: 71,   PL_CUM: 66.1, AC_CUM: 0,   DE_CUM: -71,   DL_CUM: -66.1, },
      { CDATE: '2025-08-29', AREA: 'Hull',    PE_WK: 2.7, PL_WK: 1.7, AC_WK: 0,    DE_WK: -2.7, DL_WK: -1.7, PE_CUM: 73.7, PL_CUM: 67.8, AC_CUM: 0,   DE_CUM: -73.7, DL_CUM: -67.8, },
      { CDATE: '2025-09-05', AREA: 'Hull',    PE_WK: 2.8, PL_WK: 1.7, AC_WK: 0,    DE_WK: -2.8, DL_WK: -1.7, PE_CUM: 76.5, PL_CUM: 69.5, AC_CUM: 0,   DE_CUM: -76.5, DL_CUM: -69.5, },
      { CDATE: '2025-09-12', AREA: 'Hull',    PE_WK: 2.2, PL_WK: 1.8, AC_WK: 0,    DE_WK: -2.2, DL_WK: -1.8, PE_CUM: 78.7, PL_CUM: 71.3, AC_CUM: 0,   DE_CUM: -78.7, DL_CUM: -71.3, },
      { CDATE: '2025-09-19', AREA: 'Hull',    PE_WK: 1.8, PL_WK: 2.1, AC_WK: 0,    DE_WK: -1.8, DL_WK: -2.1, PE_CUM: 80.5, PL_CUM: 73.4, AC_CUM: 0,   DE_CUM: -80.5, DL_CUM: -73.4, },
      { CDATE: '2025-09-26', AREA: 'Hull',    PE_WK: 1.5, PL_WK: 2,   AC_WK: 0,    DE_WK: -1.5, DL_WK: -2,   PE_CUM: 82,   PL_CUM: 75.4, AC_CUM: 0,   DE_CUM: -82,   DL_CUM: -75.4, },
      { CDATE: '2025-10-03', AREA: 'Hull',    PE_WK: 1,   PL_WK: 1.1, AC_WK: 0,    DE_WK: -1,   DL_WK: -1.1, PE_CUM: 82.9, PL_CUM: 76.5, AC_CUM: 0,   DE_CUM: -82.9, DL_CUM: -76.5, },
      { CDATE: '2025-10-10', AREA: 'Hull',    PE_WK: 0.5, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.9, PE_CUM: 83.5, PL_CUM: 77.4, AC_CUM: 0,   DE_CUM: -83.5, DL_CUM: -77.4, },
      { CDATE: '2025-10-17', AREA: 'Hull',    PE_WK: 1,   PL_WK: 1.6, AC_WK: 0,    DE_WK: -1,   DL_WK: -1.6, PE_CUM: 84.5, PL_CUM: 79,   AC_CUM: 0,   DE_CUM: -84.5, DL_CUM: -79, },
      { CDATE: '2025-10-24', AREA: 'Hull',    PE_WK: 1.2, PL_WK: 1.8, AC_WK: 0,    DE_WK: -1.2, DL_WK: -1.8, PE_CUM: 85.7, PL_CUM: 80.9, AC_CUM: 0,   DE_CUM: -85.7, DL_CUM: -80.9, },
      { CDATE: '2025-10-31', AREA: 'Hull',    PE_WK: 1.3, PL_WK: 1.8, AC_WK: 0,    DE_WK: -1.3, DL_WK: -1.8, PE_CUM: 86.9, PL_CUM: 82.6, AC_CUM: 0,   DE_CUM: -86.9, DL_CUM: -82.6, },
      { CDATE: '2025-11-07', AREA: 'Hull',    PE_WK: 1.4, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.4, PE_CUM: 88.3, PL_CUM: 84,   AC_CUM: 0,   DE_CUM: -88.3, DL_CUM: -84, },
      { CDATE: '2025-11-14', AREA: 'Hull',    PE_WK: 1.5, PL_WK: 1.3, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.3, PE_CUM: 89.8, PL_CUM: 85.3, AC_CUM: 0,   DE_CUM: -89.8, DL_CUM: -85.3, },
      { CDATE: '2025-11-21', AREA: 'Hull',    PE_WK: 1.3, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.3, DL_WK: -1.4, PE_CUM: 91.1, PL_CUM: 86.8, AC_CUM: 0,   DE_CUM: -91.1, DL_CUM: -86.8, },
      { CDATE: '2025-11-28', AREA: 'Hull',    PE_WK: 1.6, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.6, DL_WK: -1.5, PE_CUM: 92.7, PL_CUM: 88.2, AC_CUM: 0,   DE_CUM: -92.7, DL_CUM: -88.2, },
      { CDATE: '2025-12-05', AREA: 'Hull',    PE_WK: 1.4, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.5, PE_CUM: 94.1, PL_CUM: 89.8, AC_CUM: 0,   DE_CUM: -94.1, DL_CUM: -89.8, },
      { CDATE: '2025-12-12', AREA: 'Hull',    PE_WK: 1.3, PL_WK: 1.3, AC_WK: 0,    DE_WK: -1.3, DL_WK: -1.3, PE_CUM: 95.4, PL_CUM: 91.1, AC_CUM: 0,   DE_CUM: -95.4, DL_CUM: -91.1, },
      { CDATE: '2025-12-19', AREA: 'Hull',    PE_WK: 1.4, PL_WK: 1.1, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.1, PE_CUM: 96.8, PL_CUM: 92.2, AC_CUM: 0,   DE_CUM: -96.8, DL_CUM: -92.2, },
      { CDATE: '2025-12-26', AREA: 'Hull',    PE_WK: 0.9, PL_WK: 1,   AC_WK: 0,    DE_WK: -0.9, DL_WK: -1,   PE_CUM: 97.7, PL_CUM: 93.2, AC_CUM: 0,   DE_CUM: -97.7, DL_CUM: -93.2, },
      { CDATE: '2026-01-02', AREA: 'Hull',    PE_WK: 0.6, PL_WK: 1.1, AC_WK: 0,    DE_WK: -0.6, DL_WK: -1.1, PE_CUM: 98.2, PL_CUM: 94.3, AC_CUM: 0,   DE_CUM: -98.2, DL_CUM: -94.3, },
      { CDATE: '2026-01-09', AREA: 'Hull',    PE_WK: 0.6, PL_WK: 1.7, AC_WK: 0,    DE_WK: -0.6, DL_WK: -1.7, PE_CUM: 98.8, PL_CUM: 96,   AC_CUM: 0,   DE_CUM: -98.8, DL_CUM: -96, },
      { CDATE: '2026-01-16', AREA: 'Hull',    PE_WK: 0.3, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.9, PE_CUM: 99.1, PL_CUM: 96.9, AC_CUM: 0,   DE_CUM: -99.1, DL_CUM: -96.9, },
      { CDATE: '2026-01-23', AREA: 'Hull',    PE_WK: 0.2, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.5, PE_CUM: 99.3, PL_CUM: 97.5, AC_CUM: 0,   DE_CUM: -99.3, DL_CUM: -97.5, },
      { CDATE: '2026-01-30', AREA: 'Hull',    PE_WK: 0.2, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.5, PE_CUM: 99.5, PL_CUM: 97.9, AC_CUM: 0,   DE_CUM: -99.5, DL_CUM: -97.9, },
      { CDATE: '2026-02-06', AREA: 'Hull',    PE_WK: 0.2, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.5, PE_CUM: 99.7, PL_CUM: 98.4, AC_CUM: 0,   DE_CUM: -99.7, DL_CUM: -98.4, },
      { CDATE: '2026-02-13', AREA: 'Hull',    PE_WK: 0.1, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.4, PE_CUM: 99.8, PL_CUM: 98.8, AC_CUM: 0,   DE_CUM: -99.8, DL_CUM: -98.8, },
      { CDATE: '2026-02-20', AREA: 'Hull',    PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.9, PL_CUM: 98.9, AC_CUM: 0,   DE_CUM: -99.9, DL_CUM: -98.9, },
      { CDATE: '2026-02-27', AREA: 'Hull',    PE_WK: 0.1, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.4, PE_CUM: 100,  PL_CUM: 99.3, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -99.3, },
      { CDATE: '2026-03-06', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0.4, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.4, PE_CUM: 100,  PL_CUM: 99.6, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -99.6, },
      { CDATE: '2026-03-13', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0.4, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.4, PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-03-20', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-03-27', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-04-03', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-04-10', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-04-17', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-04-24', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-05-01', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-05-08', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-05-15', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-05-22', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-05-29', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-06-05', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-06-12', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-06-19', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-06-26', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-07-03', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-07-10', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-07-17', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-07-24', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-07-31', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-08-07', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-08-14', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-08-21', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-08-28', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-09-04', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-09-11', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-09-18', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-09-25', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-10-02', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-10-09', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-10-16', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-10-23', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-10-30', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-11-06', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-11-13', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-11-20', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-11-27', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-12-04', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-12-11', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-12-18', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2026-12-25', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-01-01', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-01-08', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-01-15', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-01-22', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-01-29', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-02-05', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-02-12', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-02-19', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-02-26', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-05', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-12', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-19', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-26', AREA: 'Hull',    PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2024-10-04', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0.1,  DE_WK: 0,    DL_WK: 0.1,  PE_CUM: 0.1,  PL_CUM: 0,    AC_CUM: 0.1, DE_CUM: 0,     DL_CUM: 0.1, },
      { CDATE: '2024-11-08', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0,   AC_WK: 0.4,  DE_WK: -0.1, DL_WK: 0.4,  PE_CUM: 0.6,  PL_CUM: 0,    AC_CUM: 0.5, DE_CUM: -0.1,  DL_CUM: 0.5, },
      { CDATE: '2024-11-15', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.1, AC_WK: 0.5,  DE_WK: -0.1, DL_WK: 0.4,  PE_CUM: 1.1,  PL_CUM: 0.1,  AC_CUM: 0.9, DE_CUM: -0.2,  DL_CUM: 0.9, },
      { CDATE: '2024-11-22', AREA: 'LQ',      PE_WK: 0.8, PL_WK: 0.5, AC_WK: 2.2,  DE_WK: 1.4,  DL_WK: 1.7,  PE_CUM: 1.9,  PL_CUM: 0.6,  AC_CUM: 3.1, DE_CUM: 1.2,   DL_CUM: 2.6, },
      { CDATE: '2024-11-29', AREA: 'LQ',      PE_WK: 1,   PL_WK: 0.6, AC_WK: -3.1, DE_WK: -4.1, DL_WK: -3.7, PE_CUM: 2.9,  PL_CUM: 1.1,  AC_CUM: 0,   DE_CUM: -2.9,  DL_CUM: -1.1, },
      { CDATE: '2024-12-06', AREA: 'LQ',      PE_WK: 1.3, PL_WK: 0.8, AC_WK: 0,    DE_WK: -1.3, DL_WK: -0.8, PE_CUM: 4.2,  PL_CUM: 1.9,  AC_CUM: 0,   DE_CUM: -4.2,  DL_CUM: -1.9, },
      { CDATE: '2024-12-13', AREA: 'LQ',      PE_WK: 1.4, PL_WK: 0.9, AC_WK: 0,    DE_WK: -1.4, DL_WK: -0.9, PE_CUM: 5.6,  PL_CUM: 2.8,  AC_CUM: 0,   DE_CUM: -5.6,  DL_CUM: -2.8, },
      { CDATE: '2024-12-20', AREA: 'LQ',      PE_WK: 1.5, PL_WK: 1.3, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.3, PE_CUM: 7,    PL_CUM: 4.1,  AC_CUM: 0,   DE_CUM: -7,    DL_CUM: -4.1, },
      { CDATE: '2024-12-27', AREA: 'LQ',      PE_WK: 1.2, PL_WK: 1,   AC_WK: 0,    DE_WK: -1.2, DL_WK: -1,   PE_CUM: 8.2,  PL_CUM: 5.1,  AC_CUM: 0,   DE_CUM: -8.2,  DL_CUM: -5.1, },
      { CDATE: '2025-01-03', AREA: 'LQ',      PE_WK: 1.2, PL_WK: 1.1, AC_WK: 0,    DE_WK: -1.2, DL_WK: -1.1, PE_CUM: 9.4,  PL_CUM: 6.2,  AC_CUM: 0,   DE_CUM: -9.4,  DL_CUM: -6.2, },
      { CDATE: '2025-01-10', AREA: 'LQ',      PE_WK: 1.5, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.5, PE_CUM: 10.8, PL_CUM: 7.7,  AC_CUM: 0,   DE_CUM: -10.8, DL_CUM: -7.7, },
      { CDATE: '2025-01-17', AREA: 'LQ',      PE_WK: 1.7, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.5, PE_CUM: 12.5, PL_CUM: 9.1,  AC_CUM: 0,   DE_CUM: -12.5, DL_CUM: -9.1, },
      { CDATE: '2025-01-24', AREA: 'LQ',      PE_WK: 2.2, PL_WK: 1.4, AC_WK: 0,    DE_WK: -2.2, DL_WK: -1.4, PE_CUM: 14.8, PL_CUM: 10.6, AC_CUM: 0,   DE_CUM: -14.8, DL_CUM: -10.6, },
      { CDATE: '2025-01-31', AREA: 'LQ',      PE_WK: 1,   PL_WK: 0.5, AC_WK: 0,    DE_WK: -1,   DL_WK: -0.5, PE_CUM: 15.8, PL_CUM: 11.1, AC_CUM: 0,   DE_CUM: -15.8, DL_CUM: -11.1, },
      { CDATE: '2025-02-07', AREA: 'LQ',      PE_WK: 3,   PL_WK: 1.5, AC_WK: 0,    DE_WK: -3,   DL_WK: -1.5, PE_CUM: 18.8, PL_CUM: 12.6, AC_CUM: 0,   DE_CUM: -18.8, DL_CUM: -12.6, },
      { CDATE: '2025-02-14', AREA: 'LQ',      PE_WK: 2.6, PL_WK: 1.6, AC_WK: 0,    DE_WK: -2.6, DL_WK: -1.6, PE_CUM: 21.4, PL_CUM: 14.3, AC_CUM: 0,   DE_CUM: -21.4, DL_CUM: -14.3, },
      { CDATE: '2025-02-21', AREA: 'LQ',      PE_WK: 2,   PL_WK: 1.9, AC_WK: 0,    DE_WK: -2,   DL_WK: -1.9, PE_CUM: 23.5, PL_CUM: 16.1, AC_CUM: 0,   DE_CUM: -23.5, DL_CUM: -16.1, },
      { CDATE: '2025-02-28', AREA: 'LQ',      PE_WK: 2.5, PL_WK: 2,   AC_WK: 0,    DE_WK: -2.5, DL_WK: -2,   PE_CUM: 25.9, PL_CUM: 18.1, AC_CUM: 0,   DE_CUM: -25.9, DL_CUM: -18.1, },
      { CDATE: '2025-03-07', AREA: 'LQ',      PE_WK: 2.7, PL_WK: 2.7, AC_WK: 0,    DE_WK: -2.7, DL_WK: -2.7, PE_CUM: 28.6, PL_CUM: 20.8, AC_CUM: 0,   DE_CUM: -28.6, DL_CUM: -20.8, },
      { CDATE: '2025-03-14', AREA: 'LQ',      PE_WK: 2.9, PL_WK: 3.2, AC_WK: 0,    DE_WK: -2.9, DL_WK: -3.2, PE_CUM: 31.6, PL_CUM: 24,   AC_CUM: 0,   DE_CUM: -31.6, DL_CUM: -24, },
      { CDATE: '2025-03-21', AREA: 'LQ',      PE_WK: 3,   PL_WK: 3.8, AC_WK: 0,    DE_WK: -3,   DL_WK: -3.8, PE_CUM: 34.6, PL_CUM: 27.9, AC_CUM: 0,   DE_CUM: -34.6, DL_CUM: -27.9, },
      { CDATE: '2025-03-28', AREA: 'LQ',      PE_WK: 3.2, PL_WK: 3.4, AC_WK: 0,    DE_WK: -3.2, DL_WK: -3.4, PE_CUM: 37.7, PL_CUM: 31.3, AC_CUM: 0,   DE_CUM: -37.7, DL_CUM: -31.3, },
      { CDATE: '2025-04-04', AREA: 'LQ',      PE_WK: 2.7, PL_WK: 3,   AC_WK: 0,    DE_WK: -2.7, DL_WK: -3,   PE_CUM: 40.4, PL_CUM: 34.3, AC_CUM: 0,   DE_CUM: -40.4, DL_CUM: -34.3, },
      { CDATE: '2025-04-11', AREA: 'LQ',      PE_WK: 2.4, PL_WK: 3.3, AC_WK: 0,    DE_WK: -2.4, DL_WK: -3.3, PE_CUM: 42.8, PL_CUM: 37.6, AC_CUM: 0,   DE_CUM: -42.8, DL_CUM: -37.6, },
      { CDATE: '2025-04-18', AREA: 'LQ',      PE_WK: 2.9, PL_WK: 2.7, AC_WK: 0,    DE_WK: -2.9, DL_WK: -2.7, PE_CUM: 45.7, PL_CUM: 40.4, AC_CUM: 0,   DE_CUM: -45.7, DL_CUM: -40.4, },
      { CDATE: '2025-04-25', AREA: 'LQ',      PE_WK: 2.8, PL_WK: 2.4, AC_WK: 0,    DE_WK: -2.8, DL_WK: -2.4, PE_CUM: 48.5, PL_CUM: 42.7, AC_CUM: 0,   DE_CUM: -48.5, DL_CUM: -42.7, },
      { CDATE: '2025-05-02', AREA: 'LQ',      PE_WK: 2.1, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.1, DL_WK: -2.3, PE_CUM: 50.6, PL_CUM: 45,   AC_CUM: 0,   DE_CUM: -50.6, DL_CUM: -45, },
      { CDATE: '2025-05-09', AREA: 'LQ',      PE_WK: 2.8, PL_WK: 2.4, AC_WK: 0,    DE_WK: -2.8, DL_WK: -2.4, PE_CUM: 53.4, PL_CUM: 47.4, AC_CUM: 0,   DE_CUM: -53.4, DL_CUM: -47.4, },
      { CDATE: '2025-05-16', AREA: 'LQ',      PE_WK: 3.6, PL_WK: 2.5, AC_WK: 0,    DE_WK: -3.6, DL_WK: -2.5, PE_CUM: 57,   PL_CUM: 49.9, AC_CUM: 0,   DE_CUM: -57,   DL_CUM: -49.9, },
      { CDATE: '2025-05-23', AREA: 'LQ',      PE_WK: 2.5, PL_WK: 3.3, AC_WK: 0,    DE_WK: -2.5, DL_WK: -3.3, PE_CUM: 59.5, PL_CUM: 53.2, AC_CUM: 0,   DE_CUM: -59.5, DL_CUM: -53.2, },
      { CDATE: '2025-05-30', AREA: 'LQ',      PE_WK: 2,   PL_WK: 3.4, AC_WK: 0,    DE_WK: -2,   DL_WK: -3.4, PE_CUM: 61.5, PL_CUM: 56.7, AC_CUM: 0,   DE_CUM: -61.5, DL_CUM: -56.7, },
      { CDATE: '2025-06-06', AREA: 'LQ',      PE_WK: 1.4, PL_WK: 2,   AC_WK: 0,    DE_WK: -1.4, DL_WK: -2,   PE_CUM: 62.9, PL_CUM: 58.7, AC_CUM: 0,   DE_CUM: -62.9, DL_CUM: -58.7, },
      { CDATE: '2025-06-13', AREA: 'LQ',      PE_WK: 2.1, PL_WK: 2.1, AC_WK: 0,    DE_WK: -2.1, DL_WK: -2.1, PE_CUM: 65,   PL_CUM: 60.8, AC_CUM: 0,   DE_CUM: -65,   DL_CUM: -60.8, },
      { CDATE: '2025-06-20', AREA: 'LQ',      PE_WK: 2.3, PL_WK: 1.5, AC_WK: 0,    DE_WK: -2.3, DL_WK: -1.5, PE_CUM: 67.3, PL_CUM: 62.3, AC_CUM: 0,   DE_CUM: -67.3, DL_CUM: -62.3, },
      { CDATE: '2025-06-27', AREA: 'LQ',      PE_WK: 1.9, PL_WK: 1.9, AC_WK: 0,    DE_WK: -1.9, DL_WK: -1.9, PE_CUM: 69.2, PL_CUM: 64.2, AC_CUM: 0,   DE_CUM: -69.2, DL_CUM: -64.2, },
      { CDATE: '2025-07-04', AREA: 'LQ',      PE_WK: 1.1, PL_WK: 2.3, AC_WK: 0,    DE_WK: -1.1, DL_WK: -2.3, PE_CUM: 70.3, PL_CUM: 66.5, AC_CUM: 0,   DE_CUM: -70.3, DL_CUM: -66.5, },
      { CDATE: '2025-07-11', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 1.7, AC_WK: 0,    DE_WK: -0.6, DL_WK: -1.7, PE_CUM: 70.9, PL_CUM: 68.2, AC_CUM: 0,   DE_CUM: -70.9, DL_CUM: -68.2, },
      { CDATE: '2025-07-18', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 1,   AC_WK: 0,    DE_WK: -0.4, DL_WK: -1,   PE_CUM: 71.2, PL_CUM: 69.2, AC_CUM: 0,   DE_CUM: -71.2, DL_CUM: -69.2, },
      { CDATE: '2025-07-25', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.5, PE_CUM: 71.7, PL_CUM: 69.7, AC_CUM: 0,   DE_CUM: -71.7, DL_CUM: -69.7, },
      { CDATE: '2025-08-01', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 71.7, PL_CUM: 69.7, AC_CUM: 0,   DE_CUM: -71.7, DL_CUM: -69.7, },
      { CDATE: '2025-08-08', AREA: 'LQ',      PE_WK: 0.1, PL_WK: 0,   AC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 71.7, PL_CUM: 69.8, AC_CUM: 0,   DE_CUM: -71.7, DL_CUM: -69.8, },
      { CDATE: '2025-08-15', AREA: 'LQ',      PE_WK: 0.3, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.2, PE_CUM: 72.1, PL_CUM: 70,   AC_CUM: 0,   DE_CUM: -72.1, DL_CUM: -70, },
      { CDATE: '2025-08-22', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.2, PE_CUM: 72.4, PL_CUM: 70.2, AC_CUM: 0,   DE_CUM: -72.4, DL_CUM: -70.2, },
      { CDATE: '2025-08-29', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.2, PE_CUM: 72.8, PL_CUM: 70.5, AC_CUM: 0,   DE_CUM: -72.8, DL_CUM: -70.5, },
      { CDATE: '2025-09-05', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.2, PE_CUM: 73.1, PL_CUM: 70.7, AC_CUM: 0,   DE_CUM: -73.1, DL_CUM: -70.7, },
      { CDATE: '2025-09-12', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.2, PE_CUM: 73.5, PL_CUM: 71,   AC_CUM: 0,   DE_CUM: -73.5, DL_CUM: -71, },
      { CDATE: '2025-09-19', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.2, PE_CUM: 74,   PL_CUM: 71.2, AC_CUM: 0,   DE_CUM: -74,   DL_CUM: -71.2, },
      { CDATE: '2025-09-26', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.2, PE_CUM: 74.4, PL_CUM: 71.5, AC_CUM: 0,   DE_CUM: -74.4, DL_CUM: -71.5, },
      { CDATE: '2025-10-03', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.2, PE_CUM: 74.8, PL_CUM: 71.7, AC_CUM: 0,   DE_CUM: -74.8, DL_CUM: -71.7, },
      { CDATE: '2025-10-10', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 75,   PL_CUM: 71.8, AC_CUM: 0,   DE_CUM: -75,   DL_CUM: -71.8, },
      { CDATE: '2025-10-17', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.2, PE_CUM: 75.6, PL_CUM: 72,   AC_CUM: 0,   DE_CUM: -75.6, DL_CUM: -72, },
      { CDATE: '2025-10-24', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.2, PE_CUM: 76.1, PL_CUM: 72.3, AC_CUM: 0,   DE_CUM: -76.1, DL_CUM: -72.3, },
      { CDATE: '2025-10-31', AREA: 'LQ',      PE_WK: 0.3, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.2, PE_CUM: 76.4, PL_CUM: 72.5, AC_CUM: 0,   DE_CUM: -76.4, DL_CUM: -72.5, },
      { CDATE: '2025-11-07', AREA: 'LQ',      PE_WK: 0.3, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.2, PE_CUM: 76.8, PL_CUM: 72.8, AC_CUM: 0,   DE_CUM: -76.8, DL_CUM: -72.8, },
      { CDATE: '2025-11-14', AREA: 'LQ',      PE_WK: 0.3, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.2, PE_CUM: 77.1, PL_CUM: 73,   AC_CUM: 0,   DE_CUM: -77.1, DL_CUM: -73, },
      { CDATE: '2025-11-21', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.3, PE_CUM: 77.5, PL_CUM: 73.3, AC_CUM: 0,   DE_CUM: -77.5, DL_CUM: -73.3, },
      { CDATE: '2025-11-28', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.3, PE_CUM: 77.9, PL_CUM: 73.5, AC_CUM: 0,   DE_CUM: -77.9, DL_CUM: -73.5, },
      { CDATE: '2025-12-05', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.3, PE_CUM: 78.3, PL_CUM: 73.8, AC_CUM: 0,   DE_CUM: -78.3, DL_CUM: -73.8, },
      { CDATE: '2025-12-12', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.3, PE_CUM: 78.8, PL_CUM: 74.2, AC_CUM: 0,   DE_CUM: -78.8, DL_CUM: -74.2, },
      { CDATE: '2025-12-19', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.4, PE_CUM: 79.3, PL_CUM: 74.6, AC_CUM: 0,   DE_CUM: -79.3, DL_CUM: -74.6, },
      { CDATE: '2025-12-26', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.5, PE_CUM: 79.8, PL_CUM: 75,   AC_CUM: 0,   DE_CUM: -79.8, DL_CUM: -75, },
      { CDATE: '2026-01-02', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.4, PE_CUM: 80.2, PL_CUM: 75.5, AC_CUM: 0,   DE_CUM: -80.2, DL_CUM: -75.5, },
      { CDATE: '2026-01-09', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.5, PE_CUM: 80.7, PL_CUM: 76,   AC_CUM: 0,   DE_CUM: -80.7, DL_CUM: -76, },
      { CDATE: '2026-01-16', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.5, PE_CUM: 81.3, PL_CUM: 76.5, AC_CUM: 0,   DE_CUM: -81.3, DL_CUM: -76.5, },
      { CDATE: '2026-01-23', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.6, PE_CUM: 81.8, PL_CUM: 77.1, AC_CUM: 0,   DE_CUM: -81.8, DL_CUM: -77.1, },
      { CDATE: '2026-01-30', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 82.4, PL_CUM: 77.7, AC_CUM: 0,   DE_CUM: -82.4, DL_CUM: -77.7, },
      { CDATE: '2026-02-06', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 82.9, PL_CUM: 78.3, AC_CUM: 0,   DE_CUM: -82.9, DL_CUM: -78.3, },
      { CDATE: '2026-02-13', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.5, PE_CUM: 83.5, PL_CUM: 78.9, AC_CUM: 0,   DE_CUM: -83.5, DL_CUM: -78.9, },
      { CDATE: '2026-02-20', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 83.7, PL_CUM: 79.1, AC_CUM: 0,   DE_CUM: -83.7, DL_CUM: -79.1, },
      { CDATE: '2026-02-27', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.5, PE_CUM: 84.3, PL_CUM: 79.6, AC_CUM: 0,   DE_CUM: -84.3, DL_CUM: -79.6, },
      { CDATE: '2026-03-06', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 84.8, PL_CUM: 80.2, AC_CUM: 0,   DE_CUM: -84.8, DL_CUM: -80.2, },
      { CDATE: '2026-03-13', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 85.4, PL_CUM: 80.8, AC_CUM: 0,   DE_CUM: -85.4, DL_CUM: -80.8, },
      { CDATE: '2026-03-20', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 86,   PL_CUM: 81.4, AC_CUM: 0,   DE_CUM: -86,   DL_CUM: -81.4, },
      { CDATE: '2026-03-27', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.5, PE_CUM: 86.5, PL_CUM: 81.9, AC_CUM: 0,   DE_CUM: -86.5, DL_CUM: -81.9, },
      { CDATE: '2026-04-03', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.5, PE_CUM: 87.1, PL_CUM: 82.4, AC_CUM: 0,   DE_CUM: -87.1, DL_CUM: -82.4, },
      { CDATE: '2026-04-10', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.5, PE_CUM: 87.7, PL_CUM: 83,   AC_CUM: 0,   DE_CUM: -87.7, DL_CUM: -83, },
      { CDATE: '2026-04-17', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.5, PE_CUM: 88.3, PL_CUM: 83.5, AC_CUM: 0,   DE_CUM: -88.3, DL_CUM: -83.5, },
      { CDATE: '2026-04-24', AREA: 'LQ',      PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 88.9, PL_CUM: 84.1, AC_CUM: 0,   DE_CUM: -88.9, DL_CUM: -84.1, },
      { CDATE: '2026-05-01', AREA: 'LQ',      PE_WK: 0.5, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.5, PE_CUM: 89.4, PL_CUM: 84.6, AC_CUM: 0,   DE_CUM: -89.4, DL_CUM: -84.6, },
      { CDATE: '2026-05-08', AREA: 'LQ',      PE_WK: 0.4, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.5, PE_CUM: 89.8, PL_CUM: 85.1, AC_CUM: 0,   DE_CUM: -89.8, DL_CUM: -85.1, },
      { CDATE: '2026-05-15', AREA: 'LQ',      PE_WK: 0.3, PL_WK: 0.7, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.7, PE_CUM: 90.1, PL_CUM: 85.8, AC_CUM: 0,   DE_CUM: -90.1, DL_CUM: -85.8, },
      { CDATE: '2026-05-22', AREA: 'LQ',      PE_WK: 0.8, PL_WK: 0.8, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.8, PE_CUM: 90.9, PL_CUM: 86.6, AC_CUM: 0,   DE_CUM: -90.9, DL_CUM: -86.6, },
      { CDATE: '2026-05-29', AREA: 'LQ',      PE_WK: 1.1, PL_WK: 0.4, AC_WK: 0,    DE_WK: -1.1, DL_WK: -0.4, PE_CUM: 92,   PL_CUM: 87,   AC_CUM: 0,   DE_CUM: -92,   DL_CUM: -87, },
      { CDATE: '2026-06-05', AREA: 'LQ',      PE_WK: 1.5, PL_WK: 0.8, AC_WK: 0,    DE_WK: -1.5, DL_WK: -0.8, PE_CUM: 93.5, PL_CUM: 87.8, AC_CUM: 0,   DE_CUM: -93.5, DL_CUM: -87.8, },
      { CDATE: '2026-06-12', AREA: 'LQ',      PE_WK: 1.2, PL_WK: 1.1, AC_WK: 0,    DE_WK: -1.2, DL_WK: -1.1, PE_CUM: 94.6, PL_CUM: 88.9, AC_CUM: 0,   DE_CUM: -94.6, DL_CUM: -88.9, },
      { CDATE: '2026-06-19', AREA: 'LQ',      PE_WK: 0.7, PL_WK: 1.5, AC_WK: 0,    DE_WK: -0.7, DL_WK: -1.5, PE_CUM: 95.3, PL_CUM: 90.4, AC_CUM: 0,   DE_CUM: -95.3, DL_CUM: -90.4, },
      { CDATE: '2026-06-26', AREA: 'LQ',      PE_WK: 0.9, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.9, DL_WK: -0.9, PE_CUM: 96.2, PL_CUM: 91.3, AC_CUM: 0,   DE_CUM: -96.2, DL_CUM: -91.3, },
      { CDATE: '2026-07-03', AREA: 'LQ',      PE_WK: 1.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -1.2, DL_WK: -0.1, PE_CUM: 97.4, PL_CUM: 91.4, AC_CUM: 0,   DE_CUM: -97.4, DL_CUM: -91.4, },
      { CDATE: '2026-07-10', AREA: 'LQ',      PE_WK: 0.8, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.1, PE_CUM: 98.1, PL_CUM: 91.5, AC_CUM: 0,   DE_CUM: -98.1, DL_CUM: -91.5, },
      { CDATE: '2026-07-17', AREA: 'LQ',      PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 98.2, PL_CUM: 91.6, AC_CUM: 0,   DE_CUM: -98.2, DL_CUM: -91.6, },
      { CDATE: '2026-07-24', AREA: 'LQ',      PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 98.3, PL_CUM: 91.7, AC_CUM: 0,   DE_CUM: -98.3, DL_CUM: -91.7, },
      { CDATE: '2026-07-31', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 98.3, PL_CUM: 91.7, AC_CUM: 0,   DE_CUM: -98.3, DL_CUM: -91.7, },
      { CDATE: '2026-08-07', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 98.3, PL_CUM: 91.7, AC_CUM: 0,   DE_CUM: -98.3, DL_CUM: -91.7, },
      { CDATE: '2026-08-14', AREA: 'LQ',      PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 98.4, PL_CUM: 91.9, AC_CUM: 0,   DE_CUM: -98.4, DL_CUM: -91.9, },
      { CDATE: '2026-08-21', AREA: 'LQ',      PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 98.6, PL_CUM: 92,   AC_CUM: 0,   DE_CUM: -98.6, DL_CUM: -92, },
      { CDATE: '2026-08-28', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 98.7, PL_CUM: 92.1, AC_CUM: 0,   DE_CUM: -98.7, DL_CUM: -92.1, },
      { CDATE: '2026-09-04', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 98.9, PL_CUM: 92.3, AC_CUM: 0,   DE_CUM: -98.9, DL_CUM: -92.3, },
      { CDATE: '2026-09-11', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 99,   PL_CUM: 92.5, AC_CUM: 0,   DE_CUM: -99,   DL_CUM: -92.5, },
      { CDATE: '2026-09-18', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.6, PE_CUM: 99.2, PL_CUM: 93.1, AC_CUM: 0,   DE_CUM: -99.2, DL_CUM: -93.1, },
      { CDATE: '2026-09-25', AREA: 'LQ',      PE_WK: 0.1, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.5, PE_CUM: 99.3, PL_CUM: 93.6, AC_CUM: 0,   DE_CUM: -99.3, DL_CUM: -93.6, },
      { CDATE: '2026-10-02', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.9, PE_CUM: 99.5, PL_CUM: 94.5, AC_CUM: 0,   DE_CUM: -99.5, DL_CUM: -94.5, },
      { CDATE: '2026-10-09', AREA: 'LQ',      PE_WK: 0.1, PL_WK: 1.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -1.1, PE_CUM: 99.6, PL_CUM: 95.6, AC_CUM: 0,   DE_CUM: -99.6, DL_CUM: -95.6, },
      { CDATE: '2026-10-16', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 1.3, AC_WK: 0,    DE_WK: -0.2, DL_WK: -1.3, PE_CUM: 99.7, PL_CUM: 96.9, AC_CUM: 0,   DE_CUM: -99.7, DL_CUM: -96.9, },
      { CDATE: '2026-10-23', AREA: 'LQ',      PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 99.9, PL_CUM: 97.1, AC_CUM: 0,   DE_CUM: -99.9, DL_CUM: -97.1, },
      { CDATE: '2026-10-30', AREA: 'LQ',      PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 100,  PL_CUM: 97.3, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -97.3, },
      { CDATE: '2026-11-06', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.2, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.2, PE_CUM: 100,  PL_CUM: 97.6, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -97.6, },
      { CDATE: '2026-11-13', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.2, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.2, PE_CUM: 100,  PL_CUM: 97.8, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -97.8, },
      { CDATE: '2026-11-20', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.3, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.3, PE_CUM: 100,  PL_CUM: 98.1, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -98.1, },
      { CDATE: '2026-11-27', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.3, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.3, PE_CUM: 100,  PL_CUM: 98.4, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -98.4, },
      { CDATE: '2026-12-04', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.3, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.3, PE_CUM: 100,  PL_CUM: 98.7, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -98.7, },
      { CDATE: '2026-12-11', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.3, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.3, PE_CUM: 100,  PL_CUM: 99,   AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -99, },
      { CDATE: '2026-12-18', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.3, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.3, PE_CUM: 100,  PL_CUM: 99.2, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -99.2, },
      { CDATE: '2026-12-25', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 100,  PL_CUM: 99.4, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -99.4, },
      { CDATE: '2027-01-01', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 100,  PL_CUM: 99.5, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -99.5, },
      { CDATE: '2027-01-08', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.2, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.2, PE_CUM: 100,  PL_CUM: 99.6, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -99.6, },
      { CDATE: '2027-01-15', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.2, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.2, PE_CUM: 100,  PL_CUM: 99.8, AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -99.8, },
      { CDATE: '2027-01-22', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0.2, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.2, PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-01-29', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-02-05', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-02-12', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-02-19', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-02-26', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-05', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-12', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-19', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-26', AREA: 'LQ',      PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2024-10-04', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0,   AC_WK: 0.1,  DE_WK: 0,    DL_WK: 0,    PE_CUM: 0.1,  PL_CUM: 0,    AC_CUM: 0.1, DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0.1,  DE_WK: -0.1, DL_WK: 0,    PE_CUM: 0.2,  PL_CUM: 0.1,  AC_CUM: 0.1, DE_CUM: -0.1,  DL_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0.2,  DE_WK: 0.1,  DL_WK: 0.1,  PE_CUM: 0.3,  PL_CUM: 0.2,  AC_CUM: 0.3, DE_CUM: -0.1,     DL_CUM: 0.2, },
      { CDATE: '2024-11-29', AREA: 'Overall', PE_WK: 0.2, PL_WK: 0.1, AC_WK: -0.3, DE_WK: -0.5, DL_WK: -0.4, PE_CUM: 0.5,  PL_CUM: 0.3,  AC_CUM: 0,   DE_CUM: -0.5,  DL_CUM: -0.3, },
      { CDATE: '2024-12-06', AREA: 'Overall', PE_WK: 0.3, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.1, PE_CUM: 0.8,  PL_CUM: 0.4,  AC_CUM: 0,   DE_CUM: -0.8,  DL_CUM: -0.4, },
      { CDATE: '2024-12-13', AREA: 'Overall', PE_WK: 0.4, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.2, PE_CUM: 1.2,  PL_CUM: 0.6,  AC_CUM: 0,   DE_CUM: -1.2,  DL_CUM: -0.6, },
      { CDATE: '2024-12-20', AREA: 'Overall', PE_WK: 0.5, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.4, PE_CUM: 1.8,  PL_CUM: 1,    AC_CUM: 0,   DE_CUM: -1.8,  DL_CUM: -1, },
      { CDATE: '2024-12-27', AREA: 'Overall', PE_WK: 0.5, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.4, PE_CUM: 2.3,  PL_CUM: 1.4,  AC_CUM: 0,   DE_CUM: -2.3,  DL_CUM: -1.4, },
      { CDATE: '2025-01-03', AREA: 'Overall', PE_WK: 0.5, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.4, PE_CUM: 2.7,  PL_CUM: 1.8,  AC_CUM: 0,   DE_CUM: -2.7,  DL_CUM: -1.8, },
      { CDATE: '2025-01-10', AREA: 'Overall', PE_WK: 0.5, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.5, PE_CUM: 3.3,  PL_CUM: 2.3,  AC_CUM: 0,   DE_CUM: -3.3,  DL_CUM: -2.3, },
      { CDATE: '2025-01-17', AREA: 'Overall', PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 3.9,  PL_CUM: 2.9,  AC_CUM: 0,   DE_CUM: -3.9,  DL_CUM: -2.9, },
      { CDATE: '2025-01-24', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.7, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.7, PE_CUM: 4.6,  PL_CUM: 3.6,  AC_CUM: 0,   DE_CUM: -4.6,  DL_CUM: -3.6, },
      { CDATE: '2025-01-31', AREA: 'Overall', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 4.9,  PL_CUM: 3.8,  AC_CUM: 0,   DE_CUM: -4.9,  DL_CUM: -3.8, },
      { CDATE: '2025-02-07', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.6, PE_CUM: 5.5,  PL_CUM: 4.4,  AC_CUM: 0,   DE_CUM: -5.5,  DL_CUM: -4.4, },
      { CDATE: '2025-02-14', AREA: 'Overall', PE_WK: 1,   PL_WK: 0.8, AC_WK: 0,    DE_WK: -1,   DL_WK: -0.8, PE_CUM: 6.5,  PL_CUM: 5.2,  AC_CUM: 0,   DE_CUM: -6.5,  DL_CUM: -5.2, },
      { CDATE: '2025-02-21', AREA: 'Overall', PE_WK: 1.1, PL_WK: 0.9, AC_WK: 0,    DE_WK: -1.1, DL_WK: -0.9, PE_CUM: 7.6,  PL_CUM: 6.1,  AC_CUM: 0,   DE_CUM: -7.6,  DL_CUM: -6.1, },
      { CDATE: '2025-02-28', AREA: 'Overall', PE_WK: 1.3, PL_WK: 1,   AC_WK: 0,    DE_WK: -1.3, DL_WK: -1,   PE_CUM: 8.9,  PL_CUM: 7.1,  AC_CUM: 0,   DE_CUM: -8.9,  DL_CUM: -7.1, },
      { CDATE: '2025-03-07', AREA: 'Overall', PE_WK: 1.5, PL_WK: 1.1, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.1, PE_CUM: 10.4, PL_CUM: 8.2,  AC_CUM: 0,   DE_CUM: -10.4, DL_CUM: -8.2, },
      { CDATE: '2025-03-14', AREA: 'Overall', PE_WK: 1.6, PL_WK: 1.3, AC_WK: 0,    DE_WK: -1.6, DL_WK: -1.3, PE_CUM: 12,   PL_CUM: 9.5,  AC_CUM: 0,   DE_CUM: -12,   DL_CUM: -9.5, },
      { CDATE: '2025-03-21', AREA: 'Overall', PE_WK: 1.9, PL_WK: 1.3, AC_WK: 0,    DE_WK: -1.9, DL_WK: -1.3, PE_CUM: 13.9, PL_CUM: 10.8, AC_CUM: 0,   DE_CUM: -13.9, DL_CUM: -10.8, },
      { CDATE: '2025-03-28', AREA: 'Overall', PE_WK: 2.2, PL_WK: 1.5, AC_WK: 0,    DE_WK: -2.2, DL_WK: -1.5, PE_CUM: 16.1, PL_CUM: 12.3, AC_CUM: 0,   DE_CUM: -16.1, DL_CUM: -12.3, },
      { CDATE: '2025-04-04', AREA: 'Overall', PE_WK: 1.6, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.6, DL_WK: -1.4, PE_CUM: 17.7, PL_CUM: 13.7, AC_CUM: 0,   DE_CUM: -17.7, DL_CUM: -13.7, },
      { CDATE: '2025-04-11', AREA: 'Overall', PE_WK: 2.4, PL_WK: 2,   AC_WK: 0,    DE_WK: -2.4, DL_WK: -2,   PE_CUM: 20.1, PL_CUM: 15.7, AC_CUM: 0,   DE_CUM: -20.1, DL_CUM: -15.7, },
      { CDATE: '2025-04-18', AREA: 'Overall', PE_WK: 2.5, PL_WK: 2.4, AC_WK: 0,    DE_WK: -2.5, DL_WK: -2.4, PE_CUM: 22.7, PL_CUM: 18.1, AC_CUM: 0,   DE_CUM: -22.7, DL_CUM: -18.1, },
      { CDATE: '2025-04-25', AREA: 'Overall', PE_WK: 2.1, PL_WK: 2.8, AC_WK: 0,    DE_WK: -2.1, DL_WK: -2.8, PE_CUM: 24.7, PL_CUM: 20.9, AC_CUM: 0,   DE_CUM: -24.7, DL_CUM: -20.9, },
      { CDATE: '2025-05-02', AREA: 'Overall', PE_WK: 1.7, PL_WK: 1.8, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.8, PE_CUM: 26.5, PL_CUM: 22.7, AC_CUM: 0,   DE_CUM: -26.5, DL_CUM: -22.7, },
      { CDATE: '2025-05-09', AREA: 'Overall', PE_WK: 2,   PL_WK: 1.9, AC_WK: 0,    DE_WK: -2,   DL_WK: -1.9, PE_CUM: 28.5, PL_CUM: 24.6, AC_CUM: 0,   DE_CUM: -28.5, DL_CUM: -24.6, },
      { CDATE: '2025-05-16', AREA: 'Overall', PE_WK: 2.4, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.4, DL_WK: -2.3, PE_CUM: 30.9, PL_CUM: 26.9, AC_CUM: 0,   DE_CUM: -30.9, DL_CUM: -26.9, },
      { CDATE: '2025-05-23', AREA: 'Overall', PE_WK: 2.6, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.6, DL_WK: -2.3, PE_CUM: 33.5, PL_CUM: 29.3, AC_CUM: 0,   DE_CUM: -33.5, DL_CUM: -29.3, },
      { CDATE: '2025-05-30', AREA: 'Overall', PE_WK: 2.2, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.2, DL_WK: -2.3, PE_CUM: 35.7, PL_CUM: 31.6, AC_CUM: 0,   DE_CUM: -35.7, DL_CUM: -31.6, },
      { CDATE: '2025-06-06', AREA: 'Overall', PE_WK: 1.4, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.6, PE_CUM: 37,   PL_CUM: 33.2, AC_CUM: 0,   DE_CUM: -37,   DL_CUM: -33.2, },
      { CDATE: '2025-06-13', AREA: 'Overall', PE_WK: 2.2, PL_WK: 1.8, AC_WK: 0,    DE_WK: -2.2, DL_WK: -1.8, PE_CUM: 39.2, PL_CUM: 35,   AC_CUM: 0,   DE_CUM: -39.2, DL_CUM: -35, },
      { CDATE: '2025-06-20', AREA: 'Overall', PE_WK: 2.5, PL_WK: 1.9, AC_WK: 0,    DE_WK: -2.5, DL_WK: -1.9, PE_CUM: 41.8, PL_CUM: 36.9, AC_CUM: 0,   DE_CUM: -41.8, DL_CUM: -36.9, },
      { CDATE: '2025-06-27', AREA: 'Overall', PE_WK: 2.7, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.7, DL_WK: -2.3, PE_CUM: 44.5, PL_CUM: 39.3, AC_CUM: 0,   DE_CUM: -44.5, DL_CUM: -39.3, },
      { CDATE: '2025-07-04', AREA: 'Overall', PE_WK: 2.3, PL_WK: 2.7, AC_WK: 0,    DE_WK: -2.3, DL_WK: -2.7, PE_CUM: 46.8, PL_CUM: 41.9, AC_CUM: 0,   DE_CUM: -46.8, DL_CUM: -41.9, },
      { CDATE: '2025-07-11', AREA: 'Overall', PE_WK: 2.5, PL_WK: 2.5, AC_WK: 0,    DE_WK: -2.5, DL_WK: -2.5, PE_CUM: 49.3, PL_CUM: 44.4, AC_CUM: 0,   DE_CUM: -49.3, DL_CUM: -44.4, },
      { CDATE: '2025-07-18', AREA: 'Overall', PE_WK: 2.6, PL_WK: 2.6, AC_WK: 0,    DE_WK: -2.6, DL_WK: -2.6, PE_CUM: 52,   PL_CUM: 47,   AC_CUM: 0,   DE_CUM: -52,   DL_CUM: -47, },
      { CDATE: '2025-07-25', AREA: 'Overall', PE_WK: 2.4, PL_WK: 2.5, AC_WK: 0,    DE_WK: -2.4, DL_WK: -2.5, PE_CUM: 54.4, PL_CUM: 49.6, AC_CUM: 0,   DE_CUM: -54.4, DL_CUM: -49.6, },
      { CDATE: '2025-08-01', AREA: 'Overall', PE_WK: 0.6, PL_WK: 0.7, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.7, PE_CUM: 55,   PL_CUM: 50.3, AC_CUM: 0,   DE_CUM: -55,   DL_CUM: -50.3, },
      { CDATE: '2025-08-08', AREA: 'Overall', PE_WK: 0.7, PL_WK: 1.1, AC_WK: 0,    DE_WK: -0.7, DL_WK: -1.1, PE_CUM: 55.6, PL_CUM: 51.4, AC_CUM: 0,   DE_CUM: -55.6, DL_CUM: -51.4, },
      { CDATE: '2025-08-15', AREA: 'Overall', PE_WK: 1.5, PL_WK: 1.9, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.9, PE_CUM: 57.1, PL_CUM: 53.4, AC_CUM: 0,   DE_CUM: -57.1, DL_CUM: -53.4, },
      { CDATE: '2025-08-22', AREA: 'Overall', PE_WK: 2.2, PL_WK: 2,   AC_WK: 0,    DE_WK: -2.2, DL_WK: -2,   PE_CUM: 59.3, PL_CUM: 55.3, AC_CUM: 0,   DE_CUM: -59.3, DL_CUM: -55.3, },
      { CDATE: '2025-08-29', AREA: 'Overall', PE_WK: 2.4, PL_WK: 1.8, AC_WK: 0,    DE_WK: -2.4, DL_WK: -1.8, PE_CUM: 61.7, PL_CUM: 57.1, AC_CUM: 0,   DE_CUM: -61.7, DL_CUM: -57.1, },
      { CDATE: '2025-09-05', AREA: 'Overall', PE_WK: 2.5, PL_WK: 1.8, AC_WK: 0,    DE_WK: -2.5, DL_WK: -1.8, PE_CUM: 64.1, PL_CUM: 58.9, AC_CUM: 0,   DE_CUM: -64.1, DL_CUM: -58.9, },
      { CDATE: '2025-09-12', AREA: 'Overall', PE_WK: 2.2, PL_WK: 1.9, AC_WK: 0,    DE_WK: -2.2, DL_WK: -1.9, PE_CUM: 66.3, PL_CUM: 60.8, AC_CUM: 0,   DE_CUM: -66.3, DL_CUM: -60.8, },
      { CDATE: '2025-09-19', AREA: 'Overall', PE_WK: 2,   PL_WK: 2.1, AC_WK: 0,    DE_WK: -2,   DL_WK: -2.1, PE_CUM: 68.3, PL_CUM: 62.9, AC_CUM: 0,   DE_CUM: -68.3, DL_CUM: -62.9, },
      { CDATE: '2025-09-26', AREA: 'Overall', PE_WK: 1.7, PL_WK: 2.1, AC_WK: 0,    DE_WK: -1.7, DL_WK: -2.1, PE_CUM: 70,   PL_CUM: 65,   AC_CUM: 0,   DE_CUM: -70,   DL_CUM: -65, },
      { CDATE: '2025-10-03', AREA: 'Overall', PE_WK: 1.2, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.2, DL_WK: -1.4, PE_CUM: 71.1, PL_CUM: 66.4, AC_CUM: 0,   DE_CUM: -71.1, DL_CUM: -66.4, },
      { CDATE: '2025-10-10', AREA: 'Overall', PE_WK: 0.6, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.9, PE_CUM: 71.7, PL_CUM: 67.3, AC_CUM: 0,   DE_CUM: -71.7, DL_CUM: -67.3, },
      { CDATE: '2025-10-17', AREA: 'Overall', PE_WK: 1.2, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.2, DL_WK: -1.6, PE_CUM: 72.9, PL_CUM: 68.9, AC_CUM: 0,   DE_CUM: -72.9, DL_CUM: -68.9, },
      { CDATE: '2025-10-24', AREA: 'Overall', PE_WK: 1.4, PL_WK: 1.7, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.7, PE_CUM: 74.3, PL_CUM: 70.5, AC_CUM: 0,   DE_CUM: -74.3, DL_CUM: -70.5, },
      { CDATE: '2025-10-31', AREA: 'Overall', PE_WK: 1.5, PL_WK: 1.7, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.7, PE_CUM: 75.8, PL_CUM: 72.2, AC_CUM: 0,   DE_CUM: -75.8, DL_CUM: -72.2, },
      { CDATE: '2025-11-07', AREA: 'Overall', PE_WK: 1.5, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.4, PE_CUM: 77.3, PL_CUM: 73.6, AC_CUM: 0,   DE_CUM: -77.3, DL_CUM: -73.6, },
      { CDATE: '2025-11-14', AREA: 'Overall', PE_WK: 1.7, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.5, PE_CUM: 79,   PL_CUM: 75.2, AC_CUM: 0,   DE_CUM: -79,   DL_CUM: -75.2, },
      { CDATE: '2025-11-21', AREA: 'Overall', PE_WK: 1.5, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.6, PE_CUM: 80.5, PL_CUM: 76.8, AC_CUM: 0,   DE_CUM: -80.5, DL_CUM: -76.8, },
      { CDATE: '2025-11-28', AREA: 'Overall', PE_WK: 1.7, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.6, PE_CUM: 82.2, PL_CUM: 78.4, AC_CUM: 0,   DE_CUM: -82.2, DL_CUM: -78.4, },
      { CDATE: '2025-12-05', AREA: 'Overall', PE_WK: 1.5, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.6, PE_CUM: 83.7, PL_CUM: 80,   AC_CUM: 0,   DE_CUM: -83.7, DL_CUM: -80, },
      { CDATE: '2025-12-12', AREA: 'Overall', PE_WK: 1.3, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.3, DL_WK: -1.4, PE_CUM: 85,   PL_CUM: 81.4, AC_CUM: 0,   DE_CUM: -85,   DL_CUM: -81.4, },
      { CDATE: '2025-12-19', AREA: 'Overall', PE_WK: 1.4, PL_WK: 1.3, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.3, PE_CUM: 86.4, PL_CUM: 82.6, AC_CUM: 0,   DE_CUM: -86.4, DL_CUM: -82.6, },
      { CDATE: '2025-12-26', AREA: 'Overall', PE_WK: 1,   PL_WK: 1.1, AC_WK: 0,    DE_WK: -1,   DL_WK: -1.1, PE_CUM: 87.4, PL_CUM: 83.7, AC_CUM: 0,   DE_CUM: -87.4, DL_CUM: -83.7, },
      { CDATE: '2026-01-02', AREA: 'Overall', PE_WK: 0.8, PL_WK: 1.2, AC_WK: 0,    DE_WK: -0.8, DL_WK: -1.2, PE_CUM: 88.2, PL_CUM: 84.9, AC_CUM: 0,   DE_CUM: -88.2, DL_CUM: -84.9, },
      { CDATE: '2026-01-09', AREA: 'Overall', PE_WK: 0.9, PL_WK: 1.7, AC_WK: 0,    DE_WK: -0.9, DL_WK: -1.7, PE_CUM: 89.1, PL_CUM: 86.6, AC_CUM: 0,   DE_CUM: -89.1, DL_CUM: -86.6, },
      { CDATE: '2026-01-16', AREA: 'Overall', PE_WK: 0.7, PL_WK: 1.2, AC_WK: 0,    DE_WK: -0.7, DL_WK: -1.2, PE_CUM: 89.8, PL_CUM: 87.8, AC_CUM: 0,   DE_CUM: -89.8, DL_CUM: -87.8, },
      { CDATE: '2026-01-23', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.9, PE_CUM: 90.5, PL_CUM: 88.7, AC_CUM: 0,   DE_CUM: -90.5, DL_CUM: -88.7, },
      { CDATE: '2026-01-30', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.9, PE_CUM: 91.2, PL_CUM: 89.6, AC_CUM: 0,   DE_CUM: -91.2, DL_CUM: -89.6, },
      { CDATE: '2026-02-06', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.9, PE_CUM: 91.9, PL_CUM: 90.5, AC_CUM: 0,   DE_CUM: -91.9, DL_CUM: -90.5, },
      { CDATE: '2026-02-13', AREA: 'Overall', PE_WK: 0.8, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.9, PE_CUM: 92.6, PL_CUM: 91.4, AC_CUM: 0,   DE_CUM: -92.6, DL_CUM: -91.4, },
      { CDATE: '2026-02-20', AREA: 'Overall', PE_WK: 0.3, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.4, PE_CUM: 93,   PL_CUM: 91.8, AC_CUM: 0,   DE_CUM: -93,   DL_CUM: -91.8, },
      { CDATE: '2026-02-27', AREA: 'Overall', PE_WK: 0.8, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.9, PE_CUM: 93.7, PL_CUM: 92.7, AC_CUM: 0,   DE_CUM: -93.7, DL_CUM: -92.7, },
      { CDATE: '2026-03-06', AREA: 'Overall', PE_WK: 0.7, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.9, PE_CUM: 94.5, PL_CUM: 93.6, AC_CUM: 0,   DE_CUM: -94.5, DL_CUM: -93.6, },
      { CDATE: '2026-03-13', AREA: 'Overall', PE_WK: 0.5, PL_WK: 0.7, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.7, PE_CUM: 95,   PL_CUM: 94.4, AC_CUM: 0,   DE_CUM: -95,   DL_CUM: -94.4, },
      { CDATE: '2026-03-20', AREA: 'Overall', PE_WK: 0.5, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.5, PE_CUM: 95.5, PL_CUM: 94.9, AC_CUM: 0,   DE_CUM: -95.5, DL_CUM: -94.9, },
      { CDATE: '2026-03-27', AREA: 'Overall', PE_WK: 0.4, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.4, PE_CUM: 95.9, PL_CUM: 95.3, AC_CUM: 0,   DE_CUM: -95.9, DL_CUM: -95.3, },
      { CDATE: '2026-04-03', AREA: 'Overall', PE_WK: 0.4, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.4, PE_CUM: 96.3, PL_CUM: 95.6, AC_CUM: 0,   DE_CUM: -96.3, DL_CUM: -95.6, },
      { CDATE: '2026-04-10', AREA: 'Overall', PE_WK: 0.3, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.4, PE_CUM: 96.6, PL_CUM: 96,   AC_CUM: 0,   DE_CUM: -96.6, DL_CUM: -96, },
      { CDATE: '2026-04-17', AREA: 'Overall', PE_WK: 0.2, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.4, PE_CUM: 96.8, PL_CUM: 96.4, AC_CUM: 0,   DE_CUM: -96.8, DL_CUM: -96.4, },
      { CDATE: '2026-04-24', AREA: 'Overall', PE_WK: 0.2, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.3, PE_CUM: 96.9, PL_CUM: 96.7, AC_CUM: 0,   DE_CUM: -96.9, DL_CUM: -96.7, },
      { CDATE: '2026-05-01', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.1, PL_CUM: 96.7, AC_CUM: 0,   DE_CUM: -97.1, DL_CUM: -96.7, },
      { CDATE: '2026-05-08', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.2, PL_CUM: 96.8, AC_CUM: 0,   DE_CUM: -97.2, DL_CUM: -96.8, },
      { CDATE: '2026-05-15', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.3, PL_CUM: 96.9, AC_CUM: 0,   DE_CUM: -97.3, DL_CUM: -96.9, },
      { CDATE: '2026-05-22', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.3, PL_CUM: 97.1, AC_CUM: 0,   DE_CUM: -97.3, DL_CUM: -97.1, },
      { CDATE: '2026-05-29', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.4, PL_CUM: 97.1, AC_CUM: 0,   DE_CUM: -97.4, DL_CUM: -97.1, },
      { CDATE: '2026-06-05', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.5, PL_CUM: 97.2, AC_CUM: 0,   DE_CUM: -97.5, DL_CUM: -97.2, },
      { CDATE: '2026-06-12', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.6, PL_CUM: 97.3, AC_CUM: 0,   DE_CUM: -97.6, DL_CUM: -97.3, },
      { CDATE: '2026-06-19', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.7, PL_CUM: 97.3, AC_CUM: 0,   DE_CUM: -97.7, DL_CUM: -97.3, },
      { CDATE: '2026-06-26', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 97.8, PL_CUM: 97.4, AC_CUM: 0,   DE_CUM: -97.8, DL_CUM: -97.4, },
      { CDATE: '2026-07-03', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0,   AC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 97.8, PL_CUM: 97.4, AC_CUM: 0,   DE_CUM: -97.8, DL_CUM: -97.4, },
      { CDATE: '2026-07-10', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0,   AC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 97.9, PL_CUM: 97.5, AC_CUM: 0,   DE_CUM: -97.9, DL_CUM: -97.5, },
      { CDATE: '2026-07-17', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0,   AC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 98,   PL_CUM: 97.5, AC_CUM: 0,   DE_CUM: -98,   DL_CUM: -97.5, },
      { CDATE: '2026-07-24', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0,   AC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 98,   PL_CUM: 97.6, AC_CUM: 0,   DE_CUM: -98,   DL_CUM: -97.6, },
      { CDATE: '2026-07-31', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 98,   PL_CUM: 97.6, AC_CUM: 0,   DE_CUM: -98,   DL_CUM: -97.6, },
      { CDATE: '2026-08-07', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 98.1, PL_CUM: 97.6, AC_CUM: 0,   DE_CUM: -98.1, DL_CUM: -97.6, },
      { CDATE: '2026-08-14', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0,   AC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 98.1, PL_CUM: 97.6, AC_CUM: 0,   DE_CUM: -98.1, DL_CUM: -97.6, },
      { CDATE: '2026-08-21', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0,   AC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 98.2, PL_CUM: 97.7, AC_CUM: 0,   DE_CUM: -98.2, DL_CUM: -97.7, },
      { CDATE: '2026-08-28', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0,   AC_WK: 0,    DE_WK: -0.1, DL_WK: 0,    PE_CUM: 98.3, PL_CUM: 97.7, AC_CUM: 0,   DE_CUM: -98.3, DL_CUM: -97.7, },
      { CDATE: '2026-09-04', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 98.3, PL_CUM: 97.8, AC_CUM: 0,   DE_CUM: -98.3, DL_CUM: -97.8, },
      { CDATE: '2026-09-11', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 98.4, PL_CUM: 97.8, AC_CUM: 0,   DE_CUM: -98.4, DL_CUM: -97.8, },
      { CDATE: '2026-09-18', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 98.5, PL_CUM: 97.9, AC_CUM: 0,   DE_CUM: -98.5, DL_CUM: -97.9, },
      { CDATE: '2026-09-25', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 98.5, PL_CUM: 98,   AC_CUM: 0,   DE_CUM: -98.5, DL_CUM: -98, },
      { CDATE: '2026-10-02', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 98.6, PL_CUM: 98,   AC_CUM: 0,   DE_CUM: -98.6, DL_CUM: -98, },
      { CDATE: '2026-10-09', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.6, PL_CUM: 98.1, AC_CUM: 0,   DE_CUM: -98.6, DL_CUM: -98.1, },
      { CDATE: '2026-10-16', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.6, PL_CUM: 98.2, AC_CUM: 0,   DE_CUM: -98.6, DL_CUM: -98.2, },
      { CDATE: '2026-10-23', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.7, PL_CUM: 98.3, AC_CUM: 0,   DE_CUM: -98.7, DL_CUM: -98.3, },
      { CDATE: '2026-10-30', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.7, PL_CUM: 98.3, AC_CUM: 0,   DE_CUM: -98.7, DL_CUM: -98.3, },
      { CDATE: '2026-11-06', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.8, PL_CUM: 98.4, AC_CUM: 0,   DE_CUM: -98.8, DL_CUM: -98.4, },
      { CDATE: '2026-11-13', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.8, PL_CUM: 98.5, AC_CUM: 0,   DE_CUM: -98.8, DL_CUM: -98.5, },
      { CDATE: '2026-11-20', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.8, PL_CUM: 98.5, AC_CUM: 0,   DE_CUM: -98.8, DL_CUM: -98.5, },
      { CDATE: '2026-11-27', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.9, PL_CUM: 98.6, AC_CUM: 0,   DE_CUM: -98.9, DL_CUM: -98.6, },
      { CDATE: '2026-12-04', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 98.9, PL_CUM: 98.7, AC_CUM: 0,   DE_CUM: -98.9, DL_CUM: -98.7, },
      { CDATE: '2026-12-11', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99,   PL_CUM: 98.8, AC_CUM: 0,   DE_CUM: -99,   DL_CUM: -98.8, },
      { CDATE: '2026-12-18', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99,   PL_CUM: 98.9, AC_CUM: 0,   DE_CUM: -99,   DL_CUM: -98.9, },
      { CDATE: '2026-12-25', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 99.1, PL_CUM: 99,   AC_CUM: 0,   DE_CUM: -99.1, DL_CUM: -99, },
      { CDATE: '2027-01-01', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.1, PL_CUM: 99,   AC_CUM: 0,   DE_CUM: -99.1, DL_CUM: -99, },
      { CDATE: '2027-01-08', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.2, PL_CUM: 99.2, AC_CUM: 0,   DE_CUM: -99.2, DL_CUM: -99.2, },
      { CDATE: '2027-01-15', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.3, PL_CUM: 99.3, AC_CUM: 0,   DE_CUM: -99.3, DL_CUM: -99.3, },
      { CDATE: '2027-01-22', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.4, PL_CUM: 99.4, AC_CUM: 0,   DE_CUM: -99.4, DL_CUM: -99.4, },
      { CDATE: '2027-01-29', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 99.5, PL_CUM: 99.4, AC_CUM: 0,   DE_CUM: -99.5, DL_CUM: -99.4, },
      { CDATE: '2027-02-05', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.5, PL_CUM: 99.5, AC_CUM: 0,   DE_CUM: -99.5, DL_CUM: -99.5, },
      { CDATE: '2027-02-12', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.6, PL_CUM: 99.6, AC_CUM: 0,   DE_CUM: -99.6, DL_CUM: -99.6, },
      { CDATE: '2027-02-19', AREA: 'Overall', PE_WK: 0,   PL_WK: 0.1, AC_WK: 0,    DE_WK: 0,    DL_WK: -0.1, PE_CUM: 99.6, PL_CUM: 99.6, AC_CUM: 0,   DE_CUM: -99.6, DL_CUM: -99.6, },
      { CDATE: '2027-02-26', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.7, PL_CUM: 99.7, AC_CUM: 0,   DE_CUM: -99.7, DL_CUM: -99.7, },
      { CDATE: '2027-03-05', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 99.9, PL_CUM: 99.9, AC_CUM: 0,   DE_CUM: -99.9, DL_CUM: -99.9, },
      { CDATE: '2027-03-12', AREA: 'Overall', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-19', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-26', AREA: 'Overall', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2024-10-04', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 0,    PL_CUM: 0,    AC_CUM: 0,   DE_CUM: 0,     DL_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 0.2,  PL_CUM: 0.2,  AC_CUM: 0,   DE_CUM: -0.2,  DL_CUM: -0.2, },
      { CDATE: '2025-01-31', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 0.3,  PL_CUM: 0.3,  AC_CUM: 0,   DE_CUM: -0.3,  DL_CUM: -0.3, },
      { CDATE: '2025-02-07', AREA: 'Topside', PE_WK: 0.3, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.3, PE_CUM: 0.6,  PL_CUM: 0.5,  AC_CUM: 0,   DE_CUM: -0.6,  DL_CUM: -0.5, },
      { CDATE: '2025-02-14', AREA: 'Topside', PE_WK: 0.3, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.3, PE_CUM: 0.9,  PL_CUM: 0.8,  AC_CUM: 0,   DE_CUM: -0.9,  DL_CUM: -0.8, },
      { CDATE: '2025-02-21', AREA: 'Topside', PE_WK: 0.4, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.3, PE_CUM: 1.2,  PL_CUM: 1.2,  AC_CUM: 0,   DE_CUM: -1.2,  DL_CUM: -1.2, },
      { CDATE: '2025-02-28', AREA: 'Topside', PE_WK: 0.4, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.4, PE_CUM: 1.6,  PL_CUM: 1.5,  AC_CUM: 0,   DE_CUM: -1.6,  DL_CUM: -1.5, },
      { CDATE: '2025-03-07', AREA: 'Topside', PE_WK: 0.4, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.3, PE_CUM: 2,    PL_CUM: 1.9,  AC_CUM: 0,   DE_CUM: -2,    DL_CUM: -1.9, },
      { CDATE: '2025-03-14', AREA: 'Topside', PE_WK: 0.4, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.3, PE_CUM: 2.4,  PL_CUM: 2.2,  AC_CUM: 0,   DE_CUM: -2.4,  DL_CUM: -2.2, },
      { CDATE: '2025-03-21', AREA: 'Topside', PE_WK: 0.5, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.4, PE_CUM: 2.9,  PL_CUM: 2.6,  AC_CUM: 0,   DE_CUM: -2.9,  DL_CUM: -2.6, },
      { CDATE: '2025-03-28', AREA: 'Topside', PE_WK: 0.5, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.5, PE_CUM: 3.4,  PL_CUM: 3.1,  AC_CUM: 0,   DE_CUM: -3.4,  DL_CUM: -3.1, },
      { CDATE: '2025-04-04', AREA: 'Topside', PE_WK: 0.6, PL_WK: 0.6, AC_WK: 0,    DE_WK: -0.6, DL_WK: -0.6, PE_CUM: 4,    PL_CUM: 3.6,  AC_CUM: 0,   DE_CUM: -4,    DL_CUM: -3.6, },
      { CDATE: '2025-04-11', AREA: 'Topside', PE_WK: 0.7, PL_WK: 0.7, AC_WK: 0,    DE_WK: -0.7, DL_WK: -0.7, PE_CUM: 4.8,  PL_CUM: 4.3,  AC_CUM: 0,   DE_CUM: -4.8,  DL_CUM: -4.3, },
      { CDATE: '2025-04-18', AREA: 'Topside', PE_WK: 0.8, PL_WK: 0.8, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.8, PE_CUM: 5.6,  PL_CUM: 5.1,  AC_CUM: 0,   DE_CUM: -5.6,  DL_CUM: -5.1, },
      { CDATE: '2025-04-25', AREA: 'Topside', PE_WK: 1,   PL_WK: 1,   AC_WK: 0,    DE_WK: -1,   DL_WK: -1,   PE_CUM: 6.6,  PL_CUM: 6,    AC_CUM: 0,   DE_CUM: -6.6,  DL_CUM: -6, },
      { CDATE: '2025-05-02', AREA: 'Topside', PE_WK: 0.9, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.9, DL_WK: -0.9, PE_CUM: 7.5,  PL_CUM: 6.9,  AC_CUM: 0,   DE_CUM: -7.5,  DL_CUM: -6.9, },
      { CDATE: '2025-05-09', AREA: 'Topside', PE_WK: 1.1, PL_WK: 1,   AC_WK: 0,    DE_WK: -1.1, DL_WK: -1,   PE_CUM: 8.6,  PL_CUM: 8,    AC_CUM: 0,   DE_CUM: -8.6,  DL_CUM: -8, },
      { CDATE: '2025-05-16', AREA: 'Topside', PE_WK: 1.5, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.5, PE_CUM: 10.1, PL_CUM: 9.4,  AC_CUM: 0,   DE_CUM: -10.1, DL_CUM: -9.4, },
      { CDATE: '2025-05-23', AREA: 'Topside', PE_WK: 1.5, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.5, PE_CUM: 11.7, PL_CUM: 11,   AC_CUM: 0,   DE_CUM: -11.7, DL_CUM: -11, },
      { CDATE: '2025-05-30', AREA: 'Topside', PE_WK: 1.6, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.6, DL_WK: -1.5, PE_CUM: 13.2, PL_CUM: 12.4, AC_CUM: 0,   DE_CUM: -13.2, DL_CUM: -12.4, },
      { CDATE: '2025-06-06', AREA: 'Topside', PE_WK: 1.4, PL_WK: 1.3, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.3, PE_CUM: 14.6, PL_CUM: 13.8, AC_CUM: 0,   DE_CUM: -14.6, DL_CUM: -13.8, },
      { CDATE: '2025-06-13', AREA: 'Topside', PE_WK: 1.8, PL_WK: 1.7, AC_WK: 0,    DE_WK: -1.8, DL_WK: -1.7, PE_CUM: 16.4, PL_CUM: 15.4, AC_CUM: 0,   DE_CUM: -16.4, DL_CUM: -15.4, },
      { CDATE: '2025-06-20', AREA: 'Topside', PE_WK: 1.7, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.6, PE_CUM: 18.1, PL_CUM: 17,   AC_CUM: 0,   DE_CUM: -18.1, DL_CUM: -17, },
      { CDATE: '2025-06-27', AREA: 'Topside', PE_WK: 1.9, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.9, DL_WK: -1.6, PE_CUM: 20.1, PL_CUM: 18.6, AC_CUM: 0,   DE_CUM: -20.1, DL_CUM: -18.6, },
      { CDATE: '2025-07-04', AREA: 'Topside', PE_WK: 2.2, PL_WK: 1.8, AC_WK: 0,    DE_WK: -2.2, DL_WK: -1.8, PE_CUM: 22.3, PL_CUM: 20.4, AC_CUM: 0,   DE_CUM: -22.3, DL_CUM: -20.4, },
      { CDATE: '2025-07-11', AREA: 'Topside', PE_WK: 2.3, PL_WK: 2.1, AC_WK: 0,    DE_WK: -2.3, DL_WK: -2.1, PE_CUM: 24.6, PL_CUM: 22.6, AC_CUM: 0,   DE_CUM: -24.6, DL_CUM: -22.6, },
      { CDATE: '2025-07-18', AREA: 'Topside', PE_WK: 2.3, PL_WK: 2.4, AC_WK: 0,    DE_WK: -2.3, DL_WK: -2.4, PE_CUM: 26.9, PL_CUM: 25,   AC_CUM: 0,   DE_CUM: -26.9, DL_CUM: -25, },
      { CDATE: '2025-07-25', AREA: 'Topside', PE_WK: 2.4, PL_WK: 2.4, AC_WK: 0,    DE_WK: -2.4, DL_WK: -2.4, PE_CUM: 29.3, PL_CUM: 27.3, AC_CUM: 0,   DE_CUM: -29.3, DL_CUM: -27.3, },
      { CDATE: '2025-08-01', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 29.3, PL_CUM: 27.3, AC_CUM: 0,   DE_CUM: -29.3, DL_CUM: -27.3, },
      { CDATE: '2025-08-08', AREA: 'Topside', PE_WK: 0.5, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.5, PE_CUM: 29.8, PL_CUM: 27.8, AC_CUM: 0,   DE_CUM: -29.8, DL_CUM: -27.8, },
      { CDATE: '2025-08-15', AREA: 'Topside', PE_WK: 1.8, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.8, DL_WK: -1.6, PE_CUM: 31.6, PL_CUM: 29.4, AC_CUM: 0,   DE_CUM: -31.6, DL_CUM: -29.4, },
      { CDATE: '2025-08-22', AREA: 'Topside', PE_WK: 2.3, PL_WK: 2.2, AC_WK: 0,    DE_WK: -2.3, DL_WK: -2.2, PE_CUM: 33.9, PL_CUM: 31.6, AC_CUM: 0,   DE_CUM: -33.9, DL_CUM: -31.6, },
      { CDATE: '2025-08-29', AREA: 'Topside', PE_WK: 2.1, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.1, DL_WK: -2.3, PE_CUM: 36,   PL_CUM: 33.9, AC_CUM: 0,   DE_CUM: -36,   DL_CUM: -33.9, },
      { CDATE: '2025-09-05', AREA: 'Topside', PE_WK: 2,   PL_WK: 2.1, AC_WK: 0,    DE_WK: -2,   DL_WK: -2.1, PE_CUM: 38,   PL_CUM: 36,   AC_CUM: 0,   DE_CUM: -38,   DL_CUM: -36, },
      { CDATE: '2025-09-12', AREA: 'Topside', PE_WK: 2.4, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.4, DL_WK: -2.3, PE_CUM: 40.4, PL_CUM: 38.3, AC_CUM: 0,   DE_CUM: -40.4, DL_CUM: -38.3, },
      { CDATE: '2025-09-19', AREA: 'Topside', PE_WK: 2.4, PL_WK: 2.3, AC_WK: 0,    DE_WK: -2.4, DL_WK: -2.3, PE_CUM: 42.8, PL_CUM: 40.6, AC_CUM: 0,   DE_CUM: -42.8, DL_CUM: -40.6, },
      { CDATE: '2025-09-26', AREA: 'Topside', PE_WK: 2.2, PL_WK: 2.4, AC_WK: 0,    DE_WK: -2.2, DL_WK: -2.4, PE_CUM: 45,   PL_CUM: 43,   AC_CUM: 0,   DE_CUM: -45,   DL_CUM: -43, },
      { CDATE: '2025-10-03', AREA: 'Topside', PE_WK: 1.6, PL_WK: 2,   AC_WK: 0,    DE_WK: -1.6, DL_WK: -2,   PE_CUM: 46.7, PL_CUM: 45,   AC_CUM: 0,   DE_CUM: -46.7, DL_CUM: -45, },
      { CDATE: '2025-10-10', AREA: 'Topside', PE_WK: 0.8, PL_WK: 0.9, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.9, PE_CUM: 47.5, PL_CUM: 45.9, AC_CUM: 0,   DE_CUM: -47.5, DL_CUM: -45.9, },
      { CDATE: '2025-10-17', AREA: 'Topside', PE_WK: 1.6, PL_WK: 1.9, AC_WK: 0,    DE_WK: -1.6, DL_WK: -1.9, PE_CUM: 49.1, PL_CUM: 47.8, AC_CUM: 0,   DE_CUM: -49.1, DL_CUM: -47.8, },
      { CDATE: '2025-10-24', AREA: 'Topside', PE_WK: 1.8, PL_WK: 1.5, AC_WK: 0,    DE_WK: -1.8, DL_WK: -1.5, PE_CUM: 50.9, PL_CUM: 49.3, AC_CUM: 0,   DE_CUM: -50.9, DL_CUM: -49.3, },
      { CDATE: '2025-10-31', AREA: 'Topside', PE_WK: 2,   PL_WK: 1.6, AC_WK: 0,    DE_WK: -2,   DL_WK: -1.6, PE_CUM: 52.9, PL_CUM: 50.9, AC_CUM: 0,   DE_CUM: -52.9, DL_CUM: -50.9, },
      { CDATE: '2025-11-07', AREA: 'Topside', PE_WK: 2,   PL_WK: 1.7, AC_WK: 0,    DE_WK: -2,   DL_WK: -1.7, PE_CUM: 54.9, PL_CUM: 52.6, AC_CUM: 0,   DE_CUM: -54.9, DL_CUM: -52.6, },
      { CDATE: '2025-11-14', AREA: 'Topside', PE_WK: 2.1, PL_WK: 2,   AC_WK: 0,    DE_WK: -2.1, DL_WK: -2,   PE_CUM: 57,   PL_CUM: 54.6, AC_CUM: 0,   DE_CUM: -57,   DL_CUM: -54.6, },
      { CDATE: '2025-11-21', AREA: 'Topside', PE_WK: 2.1, PL_WK: 2.1, AC_WK: 0,    DE_WK: -2.1, DL_WK: -2.1, PE_CUM: 59.1, PL_CUM: 56.7, AC_CUM: 0,   DE_CUM: -59.1, DL_CUM: -56.7, },
      { CDATE: '2025-11-28', AREA: 'Topside', PE_WK: 2.1, PL_WK: 2,   AC_WK: 0,    DE_WK: -2.1, DL_WK: -2,   PE_CUM: 61.2, PL_CUM: 58.7, AC_CUM: 0,   DE_CUM: -61.2, DL_CUM: -58.7, },
      { CDATE: '2025-12-05', AREA: 'Topside', PE_WK: 1.8, PL_WK: 1.9, AC_WK: 0,    DE_WK: -1.8, DL_WK: -1.9, PE_CUM: 62.9, PL_CUM: 60.6, AC_CUM: 0,   DE_CUM: -62.9, DL_CUM: -60.6, },
      { CDATE: '2025-12-12', AREA: 'Topside', PE_WK: 1.6, PL_WK: 1.7, AC_WK: 0,    DE_WK: -1.6, DL_WK: -1.7, PE_CUM: 64.5, PL_CUM: 62.3, AC_CUM: 0,   DE_CUM: -64.5, DL_CUM: -62.3, },
      { CDATE: '2025-12-19', AREA: 'Topside', PE_WK: 1.5, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.6, PE_CUM: 66,   PL_CUM: 63.9, AC_CUM: 0,   DE_CUM: -66,   DL_CUM: -63.9, },
      { CDATE: '2025-12-26', AREA: 'Topside', PE_WK: 1.3, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.3, DL_WK: -1.4, PE_CUM: 67.3, PL_CUM: 65.3, AC_CUM: 0,   DE_CUM: -67.3, DL_CUM: -65.3, },
      { CDATE: '2026-01-02', AREA: 'Topside', PE_WK: 1.3, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.3, DL_WK: -1.4, PE_CUM: 68.6, PL_CUM: 66.7, AC_CUM: 0,   DE_CUM: -68.6, DL_CUM: -66.7, },
      { CDATE: '2026-01-09', AREA: 'Topside', PE_WK: 1.5, PL_WK: 1.8, AC_WK: 0,    DE_WK: -1.5, DL_WK: -1.8, PE_CUM: 70.2, PL_CUM: 68.5, AC_CUM: 0,   DE_CUM: -70.2, DL_CUM: -68.5, },
      { CDATE: '2026-01-16', AREA: 'Topside', PE_WK: 1.6, PL_WK: 1.9, AC_WK: 0,    DE_WK: -1.6, DL_WK: -1.9, PE_CUM: 71.8, PL_CUM: 70.4, AC_CUM: 0,   DE_CUM: -71.8, DL_CUM: -70.4, },
      { CDATE: '2026-01-23', AREA: 'Topside', PE_WK: 1.7, PL_WK: 1.9, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.9, PE_CUM: 73.5, PL_CUM: 72.2, AC_CUM: 0,   DE_CUM: -73.5, DL_CUM: -72.2, },
      { CDATE: '2026-01-30', AREA: 'Topside', PE_WK: 1.7, PL_WK: 1.7, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.7, PE_CUM: 75.2, PL_CUM: 74,   AC_CUM: 0,   DE_CUM: -75.2, DL_CUM: -74, },
      { CDATE: '2026-02-06', AREA: 'Topside', PE_WK: 1.8, PL_WK: 1.9, AC_WK: 0,    DE_WK: -1.8, DL_WK: -1.9, PE_CUM: 77.1, PL_CUM: 75.8, AC_CUM: 0,   DE_CUM: -77.1, DL_CUM: -75.8, },
      { CDATE: '2026-02-13', AREA: 'Topside', PE_WK: 2,   PL_WK: 2,   AC_WK: 0,    DE_WK: -2,   DL_WK: -2,   PE_CUM: 79.1, PL_CUM: 77.8, AC_CUM: 0,   DE_CUM: -79.1, DL_CUM: -77.8, },
      { CDATE: '2026-02-20', AREA: 'Topside', PE_WK: 0.8, PL_WK: 0.8, AC_WK: 0,    DE_WK: -0.8, DL_WK: -0.8, PE_CUM: 79.9, PL_CUM: 78.7, AC_CUM: 0,   DE_CUM: -79.9, DL_CUM: -78.7, },
      { CDATE: '2026-02-27', AREA: 'Topside', PE_WK: 2.1, PL_WK: 2.1, AC_WK: 0,    DE_WK: -2.1, DL_WK: -2.1, PE_CUM: 82,   PL_CUM: 80.7, AC_CUM: 0,   DE_CUM: -82,   DL_CUM: -80.7, },
      { CDATE: '2026-03-06', AREA: 'Topside', PE_WK: 2.2, PL_WK: 2.1, AC_WK: 0,    DE_WK: -2.2, DL_WK: -2.1, PE_CUM: 84.2, PL_CUM: 82.8, AC_CUM: 0,   DE_CUM: -84.2, DL_CUM: -82.8, },
      { CDATE: '2026-03-13', AREA: 'Topside', PE_WK: 1.7, PL_WK: 1.6, AC_WK: 0,    DE_WK: -1.7, DL_WK: -1.6, PE_CUM: 85.9, PL_CUM: 84.4, AC_CUM: 0,   DE_CUM: -85.9, DL_CUM: -84.4, },
      { CDATE: '2026-03-20', AREA: 'Topside', PE_WK: 1.4, PL_WK: 1.4, AC_WK: 0,    DE_WK: -1.4, DL_WK: -1.4, PE_CUM: 87.3, PL_CUM: 85.8, AC_CUM: 0,   DE_CUM: -87.3, DL_CUM: -85.8, },
      { CDATE: '2026-03-27', AREA: 'Topside', PE_WK: 1.2, PL_WK: 1.2, AC_WK: 0,    DE_WK: -1.2, DL_WK: -1.2, PE_CUM: 88.5, PL_CUM: 87.1, AC_CUM: 0,   DE_CUM: -88.5, DL_CUM: -87.1, },
      { CDATE: '2026-04-03', AREA: 'Topside', PE_WK: 1.1, PL_WK: 1.1, AC_WK: 0,    DE_WK: -1.1, DL_WK: -1.1, PE_CUM: 89.7, PL_CUM: 88.2, AC_CUM: 0,   DE_CUM: -89.7, DL_CUM: -88.2, },
      { CDATE: '2026-04-10', AREA: 'Topside', PE_WK: 0.9, PL_WK: 1.2, AC_WK: 0,    DE_WK: -0.9, DL_WK: -1.2, PE_CUM: 90.6, PL_CUM: 89.4, AC_CUM: 0,   DE_CUM: -90.6, DL_CUM: -89.4, },
      { CDATE: '2026-04-17', AREA: 'Topside', PE_WK: 0.5, PL_WK: 1.1, AC_WK: 0,    DE_WK: -0.5, DL_WK: -1.1, PE_CUM: 91.1, PL_CUM: 90.5, AC_CUM: 0,   DE_CUM: -91.1, DL_CUM: -90.5, },
      { CDATE: '2026-04-24', AREA: 'Topside', PE_WK: 0.5, PL_WK: 0.8, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.8, PE_CUM: 91.6, PL_CUM: 91.2, AC_CUM: 0,   DE_CUM: -91.6, DL_CUM: -91.2, },
      { CDATE: '2026-05-01', AREA: 'Topside', PE_WK: 0.3, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.2, PE_CUM: 91.9, PL_CUM: 91.4, AC_CUM: 0,   DE_CUM: -91.9, DL_CUM: -91.4, },
      { CDATE: '2026-05-08', AREA: 'Topside', PE_WK: 0.3, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.2, PE_CUM: 92.3, PL_CUM: 91.6, AC_CUM: 0,   DE_CUM: -92.3, DL_CUM: -91.6, },
      { CDATE: '2026-05-15', AREA: 'Topside', PE_WK: 0.3, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.3, PE_CUM: 92.5, PL_CUM: 91.9, AC_CUM: 0,   DE_CUM: -92.5, DL_CUM: -91.9, },
      { CDATE: '2026-05-22', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.4, PE_CUM: 92.6, PL_CUM: 92.2, AC_CUM: 0,   DE_CUM: -92.6, DL_CUM: -92.2, },
      { CDATE: '2026-05-29', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 92.8, PL_CUM: 92.4, AC_CUM: 0,   DE_CUM: -92.8, DL_CUM: -92.4, },
      { CDATE: '2026-06-05', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 92.9, PL_CUM: 92.5, AC_CUM: 0,   DE_CUM: -92.9, DL_CUM: -92.5, },
      { CDATE: '2026-06-12', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 93,   PL_CUM: 92.6, AC_CUM: 0,   DE_CUM: -93,   DL_CUM: -92.6, },
      { CDATE: '2026-06-19', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 93.2, PL_CUM: 92.7, AC_CUM: 0,   DE_CUM: -93.2, DL_CUM: -92.7, },
      { CDATE: '2026-06-26', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 93.3, PL_CUM: 92.8, AC_CUM: 0,   DE_CUM: -93.3, DL_CUM: -92.8, },
      { CDATE: '2026-07-03', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 93.5, PL_CUM: 92.9, AC_CUM: 0,   DE_CUM: -93.5, DL_CUM: -92.9, },
      { CDATE: '2026-07-10', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 93.7, PL_CUM: 93,   AC_CUM: 0,   DE_CUM: -93.7, DL_CUM: -93, },
      { CDATE: '2026-07-17', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 93.8, PL_CUM: 93.1, AC_CUM: 0,   DE_CUM: -93.8, DL_CUM: -93.1, },
      { CDATE: '2026-07-24', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 94,   PL_CUM: 93.2, AC_CUM: 0,   DE_CUM: -94,   DL_CUM: -93.2, },
      { CDATE: '2026-07-31', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 94,   PL_CUM: 93.2, AC_CUM: 0,   DE_CUM: -94,   DL_CUM: -93.2, },
      { CDATE: '2026-08-07', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 94.1, PL_CUM: 93.3, AC_CUM: 0,   DE_CUM: -94.1, DL_CUM: -93.3, },
      { CDATE: '2026-08-14', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 94.3, PL_CUM: 93.4, AC_CUM: 0,   DE_CUM: -94.3, DL_CUM: -93.4, },
      { CDATE: '2026-08-21', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 94.5, PL_CUM: 93.5, AC_CUM: 0,   DE_CUM: -94.5, DL_CUM: -93.5, },
      { CDATE: '2026-08-28', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.1, PE_CUM: 94.7, PL_CUM: 93.7, AC_CUM: 0,   DE_CUM: -94.7, DL_CUM: -93.7, },
      { CDATE: '2026-09-04', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 94.9, PL_CUM: 93.8, AC_CUM: 0,   DE_CUM: -94.9, DL_CUM: -93.8, },
      { CDATE: '2026-09-11', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 95.1, PL_CUM: 94,   AC_CUM: 0,   DE_CUM: -95.1, DL_CUM: -94, },
      { CDATE: '2026-09-18', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 95.3, PL_CUM: 94.2, AC_CUM: 0,   DE_CUM: -95.3, DL_CUM: -94.2, },
      { CDATE: '2026-09-25', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 95.4, PL_CUM: 94.3, AC_CUM: 0,   DE_CUM: -95.4, DL_CUM: -94.3, },
      { CDATE: '2026-10-02', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 95.5, PL_CUM: 94.4, AC_CUM: 0,   DE_CUM: -95.5, DL_CUM: -94.4, },
      { CDATE: '2026-10-09', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.1, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.1, PE_CUM: 95.6, PL_CUM: 94.5, AC_CUM: 0,   DE_CUM: -95.6, DL_CUM: -94.5, },
      { CDATE: '2026-10-16', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 95.7, PL_CUM: 94.7, AC_CUM: 0,   DE_CUM: -95.7, DL_CUM: -94.7, },
      { CDATE: '2026-10-23', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 95.9, PL_CUM: 94.9, AC_CUM: 0,   DE_CUM: -95.9, DL_CUM: -94.9, },
      { CDATE: '2026-10-30', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 96,   PL_CUM: 95.1, AC_CUM: 0,   DE_CUM: -96,   DL_CUM: -95.1, },
      { CDATE: '2026-11-06', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 96.1, PL_CUM: 95.2, AC_CUM: 0,   DE_CUM: -96.1, DL_CUM: -95.2, },
      { CDATE: '2026-11-13', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 96.2, PL_CUM: 95.4, AC_CUM: 0,   DE_CUM: -96.2, DL_CUM: -95.4, },
      { CDATE: '2026-11-20', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 96.3, PL_CUM: 95.6, AC_CUM: 0,   DE_CUM: -96.3, DL_CUM: -95.6, },
      { CDATE: '2026-11-27', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 96.5, PL_CUM: 95.9, AC_CUM: 0,   DE_CUM: -96.5, DL_CUM: -95.9, },
      { CDATE: '2026-12-04', AREA: 'Topside', PE_WK: 0.1, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.1, DL_WK: -0.2, PE_CUM: 96.6, PL_CUM: 96.1, AC_CUM: 0,   DE_CUM: -96.6, DL_CUM: -96.1, },
      { CDATE: '2026-12-11', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 96.8, PL_CUM: 96.3, AC_CUM: 0,   DE_CUM: -96.8, DL_CUM: -96.3, },
      { CDATE: '2026-12-18', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.3, PE_CUM: 97,   PL_CUM: 96.6, AC_CUM: 0,   DE_CUM: -97,   DL_CUM: -96.6, },
      { CDATE: '2026-12-25', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 97.1, PL_CUM: 96.8, AC_CUM: 0,   DE_CUM: -97.1, DL_CUM: -96.8, },
      { CDATE: '2027-01-01', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 97.3, PL_CUM: 97.1, AC_CUM: 0,   DE_CUM: -97.3, DL_CUM: -97.1, },
      { CDATE: '2027-01-08', AREA: 'Topside', PE_WK: 0.3, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.3, PE_CUM: 97.5, PL_CUM: 97.4, AC_CUM: 0,   DE_CUM: -97.5, DL_CUM: -97.4, },
      { CDATE: '2027-01-15', AREA: 'Topside', PE_WK: 0.3, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.4, PE_CUM: 97.9, PL_CUM: 97.8, AC_CUM: 0,   DE_CUM: -97.9, DL_CUM: -97.8, },
      { CDATE: '2027-01-22', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.3, PE_CUM: 98.1, PL_CUM: 98.1, AC_CUM: 0,   DE_CUM: -98.1, DL_CUM: -98.1, },
      { CDATE: '2027-01-29', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 98.3, PL_CUM: 98.3, AC_CUM: 0,   DE_CUM: -98.3, DL_CUM: -98.3, },
      { CDATE: '2027-02-05', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 98.5, PL_CUM: 98.4, AC_CUM: 0,   DE_CUM: -98.5, DL_CUM: -98.4, },
      { CDATE: '2027-02-12', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 98.6, PL_CUM: 98.6, AC_CUM: 0,   DE_CUM: -98.6, DL_CUM: -98.6, },
      { CDATE: '2027-02-19', AREA: 'Topside', PE_WK: 0.2, PL_WK: 0.2, AC_WK: 0,    DE_WK: -0.2, DL_WK: -0.2, PE_CUM: 98.8, PL_CUM: 98.8, AC_CUM: 0,   DE_CUM: -98.8, DL_CUM: -98.8, },
      { CDATE: '2027-02-26', AREA: 'Topside', PE_WK: 0.3, PL_WK: 0.3, AC_WK: 0,    DE_WK: -0.3, DL_WK: -0.3, PE_CUM: 99.1, PL_CUM: 99.1, AC_CUM: 0,   DE_CUM: -99.1, DL_CUM: -99.1, },
      { CDATE: '2027-03-05', AREA: 'Topside', PE_WK: 0.5, PL_WK: 0.5, AC_WK: 0,    DE_WK: -0.5, DL_WK: -0.5, PE_CUM: 99.6, PL_CUM: 99.6, AC_CUM: 0,   DE_CUM: -99.6, DL_CUM: -99.6, },
      { CDATE: '2027-03-12', AREA: 'Topside', PE_WK: 0.4, PL_WK: 0.4, AC_WK: 0,    DE_WK: -0.4, DL_WK: -0.4, PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-19', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      { CDATE: '2027-03-26', AREA: 'Topside', PE_WK: 0,   PL_WK: 0,   AC_WK: 0,    DE_WK: 0,    DL_WK: 0,    PE_CUM: 100,  PL_CUM: 100,  AC_CUM: 0,   DE_CUM: -100,  DL_CUM: -100, },
      ],


    Queries: {
      
      SQL1: [
        {
          // Timeline
          CUTOFF      : '2024-11-22',
          START_DATE  : '2024-10-04',
          END_DATE    : '2026-03-26',
        },
      ],

    },
}