import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 570,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      
    },

    
    DataItems: [
      { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 5, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 5,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 4, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 14, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 19, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 38,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 12, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 51,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 58,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 65,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 68,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 8,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 76,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 0,  AS_WK: 1, AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 1,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 8,   PF_CUM: 0,   AS_CUM: 1,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 1,  AS_WK: 5, AF_WK: 2, PS_CUM: 11,  PF_CUM: 1,   AS_CUM: 6,  AF_CUM: 2, },
      { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 5,  AS_WK: 4, AF_WK: 4, PS_CUM: 11,  PF_CUM: 6,   AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 11,  PF_CUM: 8,   AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 13,  PF_CUM: 11,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 16,  PF_CUM: 11,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 8,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 24,  PF_CUM: 11,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 24,  PF_CUM: 15,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 25,  PF_CUM: 16,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 21,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 31,  PF_CUM: 24,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 32,  PF_CUM: 25,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 32,  PF_CUM: 27,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 38,  PF_CUM: 30,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 41,  PF_CUM: 31,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 46,  PF_CUM: 36,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 49,  PF_CUM: 41,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 54,  PF_CUM: 41,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 54,  PF_CUM: 49,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 55,  PF_CUM: 51,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 11, PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 66,  PF_CUM: 54,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 10, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 76,  PF_CUM: 54,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 59,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 10, PF_WK: 12, AS_WK: 0, AF_WK: 0, PS_CUM: 92,  PF_CUM: 71,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 94,  PF_CUM: 81,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 95,  PF_CUM: 82,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 95,  PF_CUM: 92,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 95,  PF_CUM: 94,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 97,  PF_CUM: 95,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 9,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 106, PF_CUM: 95,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 108, PF_CUM: 96,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 115, PF_CUM: 97,  AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 9,  AS_WK: 0, AF_WK: 0, PS_CUM: 115, PF_CUM: 106, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 117, PF_CUM: 111, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 121, PF_CUM: 115, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 124, PF_CUM: 115, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 121, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 122, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 122, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 122, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 130, PF_CUM: 130, AS_CUM: 10, AF_CUM: 6, },
      { CDATE: '2024-10-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 0,  AS_WK: 5, AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 5,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 5, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 5,  AF_CUM: 5, },
      { CDATE: '2024-11-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 3,  AS_WK: 6, AF_WK: 0, PS_CUM: 6,   PF_CUM: 3,   AS_CUM: 11, AF_CUM: 5, },
      { CDATE: '2024-11-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 3,  AS_WK: 2, AF_WK: 2, PS_CUM: 9,   PF_CUM: 6,   AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2024-11-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 12,  PF_CUM: 9,   AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2024-12-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 13,  PF_CUM: 9,   AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2024-12-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 15,  PF_CUM: 12,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2024-12-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 18,  PF_CUM: 12,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2024-12-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 14,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-01-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 14,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-01-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 16,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-01-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 18,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-01-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 25,  PF_CUM: 18,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-01-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 25,  PF_CUM: 20,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-02-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 8,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 25,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-02-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 29,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-02-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-02-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-03-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-03-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-03-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-03-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-04-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-04-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-04-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-04-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-05-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-05-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-05-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-05-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-05-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-06-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-06-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-06-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-06-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-07-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-07-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-07-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-07-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-08-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-08-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-08-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-08-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-08-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-09-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-09-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-09-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-09-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-10-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-10-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-10-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-10-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-10-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-11-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-11-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-11-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-11-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-12-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-12-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-12-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2025-12-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-01-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-01-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-01-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-01-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 35,  PF_CUM: 33,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-01-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 35,  PF_CUM: 35,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-02-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 35,  PF_CUM: 35,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-02-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 35,  PF_CUM: 35,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-02-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 35,  PF_CUM: 35,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2026-02-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 35,  PF_CUM: 35,  AS_CUM: 13, AF_CUM: 7, },
      { CDATE: '2024-10-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 11,  PF_CUM: 3,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 15,  PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 10, PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 25,  PF_CUM: 11,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 3,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 28,  PF_CUM: 15,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 13, PF_WK: 11, AS_WK: 0, AF_WK: 0, PS_CUM: 41,  PF_CUM: 26,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 14, PF_WK: 13, AS_WK: 0, AF_WK: 0, PS_CUM: 55,  PF_CUM: 39,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 10, PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 65,  PF_CUM: 47,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 7,  AS_WK: 0, AF_WK: 0, PS_CUM: 70,  PF_CUM: 54,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 11, AS_WK: 0, AF_WK: 0, PS_CUM: 76,  PF_CUM: 65,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 15, PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 91,  PF_CUM: 71,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 8,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 99,  PF_CUM: 76,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 7,  AS_WK: 0, AF_WK: 0, PS_CUM: 103, PF_CUM: 83,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 7,  PF_WK: 9,  AS_WK: 0, AF_WK: 0, PS_CUM: 110, PF_CUM: 92,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 11, AS_WK: 0, AF_WK: 0, PS_CUM: 112, PF_CUM: 103, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 4,  PF_WK: 7,  AS_WK: 0, AF_WK: 0, PS_CUM: 116, PF_CUM: 110, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 13, PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 129, PF_CUM: 112, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 8,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 137, PF_CUM: 116, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 16, PF_WK: 13, AS_WK: 0, AF_WK: 0, PS_CUM: 153, PF_CUM: 129, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 11, AS_WK: 0, AF_WK: 0, PS_CUM: 159, PF_CUM: 140, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 13, AS_WK: 0, AF_WK: 0, PS_CUM: 159, PF_CUM: 153, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 6,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 165, PF_CUM: 159, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 11, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 176, PF_CUM: 159, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 8,  PF_WK: 7,  AS_WK: 0, AF_WK: 0, PS_CUM: 184, PF_CUM: 166, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 11, PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 195, PF_CUM: 174, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 195, PF_CUM: 174, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 5,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 200, PF_CUM: 180, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 22, PF_WK: 15, AS_WK: 0, AF_WK: 0, PS_CUM: 222, PF_CUM: 195, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 13, PF_WK: 13, AS_WK: 0, AF_WK: 0, PS_CUM: 235, PF_CUM: 208, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 24, AS_WK: 0, AF_WK: 0, PS_CUM: 235, PF_CUM: 232, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 2,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 235, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 235, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'CUTTING',  PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 237, PF_CUM: 237, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 5, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 5,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 4, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 14, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 19, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 38,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 12, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 51,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 7,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 58,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 7,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 65,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 68,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 8,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 76,  PF_CUM: 0,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 6,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 6,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 6,   AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 11,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 13, AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 24,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 34,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 36,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 42,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 50,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 52,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 62,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 65,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 68,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 78,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 82,  AS_CUM: 9,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 9,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 11,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 12,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 13,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 14,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 15,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 20,  PF_CUM: 4,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 21,  PF_CUM: 5,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 21,  PF_CUM: 11,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 23,  PF_CUM: 11,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 24,  PF_CUM: 12,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 24,  PF_CUM: 12,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 26,  PF_CUM: 16,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 26,  PF_CUM: 19,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 31,  PF_CUM: 21,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 34,  PF_CUM: 21,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 36,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 38,  PF_CUM: 23,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 38,  PF_CUM: 24,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 38,  PF_CUM: 26,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 38,  PF_CUM: 26,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 40,  PF_CUM: 28,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 40,  PF_CUM: 32,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 42,  PF_CUM: 34,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 42,  PF_CUM: 36,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 42,  PF_CUM: 38,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 44,  PF_CUM: 38,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 46,  PF_CUM: 38,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 46,  PF_CUM: 40,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 42,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 42,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 42,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 42,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 44,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 46,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 46,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 6,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 9,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 9,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 12,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 12,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 7,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 21,  PF_CUM: 3,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 6,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 5,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 5,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 6,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 11,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 17,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 21,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 28,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 29,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 29,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 29,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 29,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 8,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 13,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 16,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 12, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 28,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 15, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 43,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 12, PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 55,  PF_CUM: 4,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 60,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 14, PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 12,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 77,  PF_CUM: 16,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 18,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 9,  PF_WK: 17, AS_WK: 0, AF_WK: 0, PS_CUM: 91,  PF_CUM: 35,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 10, PF_WK: 13, AS_WK: 0, AF_WK: 0, PS_CUM: 101, PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 12, PF_WK: 12, AS_WK: 0, AF_WK: 0, PS_CUM: 113, PF_CUM: 60,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 5,  PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 118, PF_CUM: 70,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 120, PF_CUM: 76,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 124, PF_CUM: 84,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 15, PF_WK: 9,  AS_WK: 0, AF_WK: 0, PS_CUM: 139, PF_CUM: 93,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 9,  PF_WK: 11, AS_WK: 0, AF_WK: 0, PS_CUM: 148, PF_CUM: 104, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 13, PF_WK: 7,  AS_WK: 0, AF_WK: 0, PS_CUM: 161, PF_CUM: 111, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 6,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 167, PF_CUM: 117, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 167, PF_CUM: 117, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 4,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 171, PF_CUM: 120, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 171, PF_CUM: 120, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 10, PF_WK: 9,  AS_WK: 0, AF_WK: 0, PS_CUM: 181, PF_CUM: 129, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 8,  PF_WK: 18, AS_WK: 0, AF_WK: 0, PS_CUM: 189, PF_CUM: 147, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 12, PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 201, PF_CUM: 150, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 13, PF_WK: 11, AS_WK: 0, AF_WK: 0, PS_CUM: 214, PF_CUM: 161, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 24, PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 238, PF_CUM: 167, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 241, PF_CUM: 167, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 2,  PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 177, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 14, AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 191, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 192, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 198, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 17, AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 215, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 24, AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 239, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'ASSEMBLY', PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 243, PF_CUM: 243, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 4,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 4,   PF_CUM: 3,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 10,  PF_CUM: 4,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 16,  PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 7,  AS_WK: 0, AF_WK: 0, PS_CUM: 16,  PF_CUM: 14,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 16,  PF_CUM: 16,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 16,  PF_CUM: 16,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 12, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 28,  PF_CUM: 16,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 32,  PF_CUM: 18,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 12, AS_WK: 0, AF_WK: 0, PS_CUM: 32,  PF_CUM: 30,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 38,  PF_CUM: 32,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 10, PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 48,  PF_CUM: 32,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 48,  PF_CUM: 38,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 48,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 48,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 7,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 55,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 61,  PF_CUM: 48,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 5,  PF_WK: 9,  AS_WK: 0, AF_WK: 0, PS_CUM: 66,  PF_CUM: 57,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 72,  PF_CUM: 62,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 8,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 66,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'COSCO', STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 80,  PF_CUM: 80,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 2,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 8,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 9,   PF_CUM: 2,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 9,   PF_CUM: 5,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 10,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 14,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 15,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 18,  PF_CUM: 8,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 18,  PF_CUM: 10,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 15,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 19,  PF_CUM: 18,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 20,  PF_CUM: 18,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 21,  PF_CUM: 19,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 21,  PF_CUM: 19,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 25,  PF_CUM: 19,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 25,  PF_CUM: 20,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 27,  PF_CUM: 21,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 31,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 31,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 31,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 33,  PF_CUM: 27,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 35,  PF_CUM: 29,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 7,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 42,  PF_CUM: 33,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 8,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 33,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 5,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 55,  PF_CUM: 34,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 59,  PF_CUM: 38,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 63,  PF_CUM: 40,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 63,  PF_CUM: 42,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 12, AS_WK: 0, AF_WK: 0, PS_CUM: 65,  PF_CUM: 54,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 9,  PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 59,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 63,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 64,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 9,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 73,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'HULL',    YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 74,  PF_CUM: 74,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 1,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 2,   PF_CUM: 1,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 2,   PF_CUM: 1,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 4,   PF_CUM: 1,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 6,   PF_CUM: 2,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 6,   PF_CUM: 2,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 2,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 4,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 6,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'LQ',      YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 7,   PF_CUM: 7,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-10-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-11-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2024-12-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-01-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-02-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-03-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-04-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 0,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 3,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 3,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-05-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 5,   PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 9,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 14,  PF_CUM: 0,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 11, PF_WK: 3,  AS_WK: 0, AF_WK: 0, PS_CUM: 25,  PF_CUM: 3,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 12, PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 37,  PF_CUM: 5,   AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-06-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 9,  AS_WK: 0, AF_WK: 0, PS_CUM: 39,  PF_CUM: 14,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-04', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 9,  PF_WK: 11, AS_WK: 0, AF_WK: 0, PS_CUM: 48,  PF_CUM: 25,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-11', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 12, AS_WK: 0, AF_WK: 0, PS_CUM: 50,  PF_CUM: 37,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-18', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 4,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 54,  PF_CUM: 39,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-07-25', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 13, PF_WK: 10, AS_WK: 0, AF_WK: 0, PS_CUM: 67,  PF_CUM: 49,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-01', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 8,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 75,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-08', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 75,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-15', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 76,  PF_CUM: 50,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-22', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 5,  PF_WK: 11, AS_WK: 0, AF_WK: 0, PS_CUM: 81,  PF_CUM: 61,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-08-29', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 81,  PF_CUM: 69,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 1,  PF_WK: 8,  AS_WK: 0, AF_WK: 0, PS_CUM: 82,  PF_CUM: 77,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 12, PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 94,  PF_CUM: 81,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 2,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 96,  PF_CUM: 81,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-09-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 6,  PF_WK: 7,  AS_WK: 0, AF_WK: 0, PS_CUM: 102, PF_CUM: 88,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-03', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 14, PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 116, PF_CUM: 94,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-10', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 1,  AS_WK: 0, AF_WK: 0, PS_CUM: 116, PF_CUM: 95,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-17', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 2,  AS_WK: 0, AF_WK: 0, PS_CUM: 116, PF_CUM: 97,  AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-24', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 12, AS_WK: 0, AF_WK: 0, PS_CUM: 116, PF_CUM: 109, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-10-31', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 9,  PF_WK: 7,  AS_WK: 0, AF_WK: 0, PS_CUM: 125, PF_CUM: 116, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-07', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 9,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 134, PF_CUM: 116, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-14', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 10, PF_WK: 6,  AS_WK: 0, AF_WK: 0, PS_CUM: 144, PF_CUM: 122, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-21', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 29, PF_WK: 5,  AS_WK: 0, AF_WK: 0, PS_CUM: 173, PF_CUM: 127, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-11-28', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 13, PF_WK: 13, AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 140, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-05', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 18, AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 158, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-12', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 24, AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 182, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-19', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 4,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2025-12-26', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-02', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-09', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-16', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-23', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-01-30', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-06', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-13', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-20', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
      { CDATE: '2026-02-27', AREA: 'TOPSIDE', YARD: 'HHI',   STAGE: 'PAINT',    PS_WK: 0,  PF_WK: 0,  AS_WK: 0, AF_WK: 0, PS_CUM: 186, PF_CUM: 186, AS_CUM: 0,  AF_CUM: 0, },
    ],


    Queries: {
      
      SQL1: [
        {
          // Timeline
          TL_CUTOFF               : '2024-11-22',
        },
      ],

    },
}