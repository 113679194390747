import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 660,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
    },

    DataItems: [
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 33,   A_WK: 0,    P_CUM: 33,    A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 157,  A_WK: 0,    P_CUM: 191,   A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 254,  A_WK: 0,    P_CUM: 446,   A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 55,   A_WK: 0,    P_CUM: 501,   A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 127,  A_WK: 0,    P_CUM: 629,   A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 306,  A_WK: 0,    P_CUM: 935,   A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 359,  A_WK: 0,    P_CUM: 1295,  A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 380,  A_WK: 0,    P_CUM: 1675,  A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 454,  A_WK: 0,    P_CUM: 2130,  A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 233,  A_WK: 0,    P_CUM: 2363,  A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 418,  A_WK: 0,    P_CUM: 2782,  A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 297,  A_WK: 0,    P_CUM: 3080,  A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 45,   A_WK: 0,    P_CUM: 3125,  A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 74,   A_WK: 0,    P_CUM: 3199,  A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 3199,  A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 3199,  A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Commissioning', GROUP: 'Commissioning',  CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 3199,  A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 106,  A_WK: 9,    P_CUM: 106,   A_CUM: 9, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 1040, A_WK: 0,    P_CUM: 1146,  A_CUM: 9, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 3202, A_WK: 0,    P_CUM: 4348,  A_CUM: 9, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 3167, A_WK: 0,    P_CUM: 7516,  A_CUM: 9, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 2633, A_WK: 0,    P_CUM: 10149, A_CUM: 9, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 3890, A_WK: 0,    P_CUM: 14040, A_CUM: 9, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 5341, A_WK: 0,    P_CUM: 19381, A_CUM: 9, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 3100, A_WK: 0,    P_CUM: 22482, A_CUM: 9, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 2731, A_WK: 0,    P_CUM: 25213, A_CUM: 9, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 3252, A_WK: 0,    P_CUM: 28465, A_CUM: 9, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 960,  A_WK: 0,    P_CUM: 29426, A_CUM: 9, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 106,  A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'COSCO',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 29533, A_CUM: 9, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 28,   A_WK: 0,    P_CUM: 28,    A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 51,   A_WK: 0,    P_CUM: 80,    A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 148,  A_WK: 0,    P_CUM: 228,   A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 424,  A_WK: 0,    P_CUM: 653,   A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 661,  A_WK: 0,    P_CUM: 1314,  A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 991,  A_WK: 0,    P_CUM: 2305,  A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 521,  A_WK: 0,    P_CUM: 2827,  A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 867,  A_WK: 0,    P_CUM: 3694,  A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 571,  A_WK: 0,    P_CUM: 4265,  A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 799,  A_WK: 0,    P_CUM: 5065,  A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 685,  A_WK: 0,    P_CUM: 5750,  A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 904,  A_WK: 0,    P_CUM: 6654,  A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 559,  A_WK: 0,    P_CUM: 7214,  A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 264,  A_WK: 0,    P_CUM: 7479,  A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 61,   A_WK: 0,    P_CUM: 7540,  A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 198,  A_WK: 0,    P_CUM: 7738,  A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 331,  A_WK: 0,    P_CUM: 8070,  A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 606,  A_WK: 0,    P_CUM: 8676,  A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 728,  A_WK: 0,    P_CUM: 9405,  A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 745,  A_WK: 0,    P_CUM: 10150, A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 905,  A_WK: 0,    P_CUM: 11056, A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 736,  A_WK: 0,    P_CUM: 11793, A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 540,  A_WK: 0,    P_CUM: 12333, A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 52,   A_WK: 0,    P_CUM: 12386, A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 94,   A_WK: 0,    P_CUM: 12480, A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 12480, A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 12480, A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 12480, A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Elect. / Instr. / Tel.', P_WK: 0,    A_WK: 0,    P_CUM: 12480, A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 59,   A_WK: 0,    P_CUM: 59,    A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 152,  A_WK: 0,    P_CUM: 211,   A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 219,  A_WK: 0,    P_CUM: 430,   A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 111,  A_WK: 0,    P_CUM: 542,   A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 160,  A_WK: 0,    P_CUM: 702,   A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 56,   A_WK: 0,    P_CUM: 758,   A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 33,   A_WK: 0,    P_CUM: 792,   A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 74,   A_WK: 0,    P_CUM: 866,   A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 95,   A_WK: 0,    P_CUM: 961,   A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 122,  A_WK: 0,    P_CUM: 1084,  A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 64,   A_WK: 0,    P_CUM: 1149,  A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 82,   A_WK: 0,    P_CUM: 1231,  A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 92,   A_WK: 0,    P_CUM: 1324,  A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 24,   A_WK: 0,    P_CUM: 1348,  A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 24,   A_WK: 0,    P_CUM: 1372,  A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 54,   A_WK: 0,    P_CUM: 1427,  A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 91,   A_WK: 0,    P_CUM: 1518,  A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 157,  A_WK: 0,    P_CUM: 1675,  A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 65,   A_WK: 0,    P_CUM: 1741,  A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 49,   A_WK: 0,    P_CUM: 1790,  A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 2,    A_WK: 0,    P_CUM: 1793,  A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 57,   A_WK: 0,    P_CUM: 1850,  A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 97,   A_WK: 0,    P_CUM: 1947,  A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 1947,  A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 1947,  A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 1947,  A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 1947,  A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 1947,  A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'HVAC & Architecture',    P_WK: 0,    A_WK: 0,    P_CUM: 1947,  A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 12,   A_WK: 0,    P_CUM: 12,    A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 11,   A_WK: 0,    P_CUM: 24,    A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 8,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 32,    A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 102,  A_WK: 0,    P_CUM: 134,   A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 101,  A_WK: 0,    P_CUM: 236,   A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 236,   A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Load-out  and Others',   P_WK: 0,    A_WK: 0,    P_CUM: 236,   A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 9,    A_WK: 0,    P_CUM: 9,     A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 17,   A_WK: 0,    P_CUM: 27,    A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 65,   A_WK: 0,    P_CUM: 92,    A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 124,  A_WK: 0,    P_CUM: 217,   A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 133,  A_WK: 0,    P_CUM: 350,   A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 180,  A_WK: 0,    P_CUM: 530,   A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 264,  A_WK: 0,    P_CUM: 795,   A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 372,  A_WK: 0,    P_CUM: 1168,  A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 272,  A_WK: 0,    P_CUM: 1441,  A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 174,  A_WK: 0,    P_CUM: 1615,  A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 134,  A_WK: 0,    P_CUM: 1750,  A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 167,  A_WK: 0,    P_CUM: 1917,  A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 74,   A_WK: 0,    P_CUM: 1992,  A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 37,   A_WK: 0,    P_CUM: 2029,  A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 156,  A_WK: 0,    P_CUM: 2186,  A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 206,  A_WK: 0,    P_CUM: 2393,  A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 220,  A_WK: 0,    P_CUM: 2613,  A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 248,  A_WK: 0,    P_CUM: 2861,  A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 216,  A_WK: 0,    P_CUM: 3078,  A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 167,  A_WK: 0,    P_CUM: 3245,  A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 54,   A_WK: 0,    P_CUM: 3300,  A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 75,   A_WK: 0,    P_CUM: 3376,  A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 105,  A_WK: 0,    P_CUM: 3481,  A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 5,    A_WK: 0,    P_CUM: 3487,  A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 24,   A_WK: 0,    P_CUM: 3511,  A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 3511,  A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 3511,  A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 3511,  A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Mechanical',             P_WK: 0,    A_WK: 0,    P_CUM: 3511,  A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 9,    A_WK: 0,    P_CUM: 9,     A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 29,   A_WK: 0,    P_CUM: 39,    A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 113,  A_WK: 0,    P_CUM: 152,   A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 14,   A_WK: 0,    P_CUM: 167,   A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 15,   A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Others',                 P_WK: 0,    A_WK: 0,    P_CUM: 183,   A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 99,   A_WK: 0,    P_CUM: 99,    A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 249,  A_WK: 0,    P_CUM: 349,   A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 373,  A_WK: 0,    P_CUM: 722,   A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 385,  A_WK: 0,    P_CUM: 1108,  A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 444,  A_WK: 0,    P_CUM: 1552,  A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 685,  A_WK: 0,    P_CUM: 2238,  A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 539,  A_WK: 0,    P_CUM: 2778,  A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 689,  A_WK: 0,    P_CUM: 3467,  A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 527,  A_WK: 0,    P_CUM: 3995,  A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 269,  A_WK: 0,    P_CUM: 4265,  A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 242,  A_WK: 0,    P_CUM: 4508,  A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 172,  A_WK: 0,    P_CUM: 4680,  A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 36,   A_WK: 0,    P_CUM: 4716,  A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 39,   A_WK: 0,    P_CUM: 4756,  A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 47,   A_WK: 0,    P_CUM: 4804,  A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 61,   A_WK: 0,    P_CUM: 4865,  A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 61,   A_WK: 0,    P_CUM: 4926,  A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 49,   A_WK: 0,    P_CUM: 4975,  A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 55,   A_WK: 0,    P_CUM: 5030,  A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 73,   A_WK: 0,    P_CUM: 5104,  A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 61,   A_WK: 0,    P_CUM: 5165,  A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 62,   A_WK: 0,    P_CUM: 5227,  A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 176,  A_WK: 0,    P_CUM: 5404,  A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 73,   A_WK: 0,    P_CUM: 5477,  A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 29,   A_WK: 0,    P_CUM: 5507,  A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 5507,  A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 5507,  A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Painting',               P_WK: 0,    A_WK: 0,    P_CUM: 5507,  A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 53,   A_WK: 0,    P_CUM: 53,    A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 186,  A_WK: 0,    P_CUM: 240,   A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 359,  A_WK: 0,    P_CUM: 599,   A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 926,  A_WK: 0,    P_CUM: 1526,  A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1140, A_WK: 0,    P_CUM: 2666,  A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1685, A_WK: 0,    P_CUM: 4351,  A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 985,  A_WK: 0,    P_CUM: 5337,  A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1635, A_WK: 0,    P_CUM: 6972,  A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1340, A_WK: 0,    P_CUM: 8313,  A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1566, A_WK: 0,    P_CUM: 9879,  A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1227, A_WK: 0,    P_CUM: 11106, A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1175, A_WK: 0,    P_CUM: 12282, A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 159,  A_WK: 0,    P_CUM: 12441, A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 20,   A_WK: 0,    P_CUM: 12461, A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 134,  A_WK: 0,    P_CUM: 12596, A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 454,  A_WK: 0,    P_CUM: 13050, A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 845,  A_WK: 0,    P_CUM: 13896, A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1354, A_WK: 0,    P_CUM: 15250, A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1200, A_WK: 0,    P_CUM: 16451, A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1289, A_WK: 0,    P_CUM: 17740, A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 1189, A_WK: 0,    P_CUM: 18930, A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 471,  A_WK: 0,    P_CUM: 19401, A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 104,  A_WK: 0,    P_CUM: 19506, A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 66,   A_WK: 0,    P_CUM: 19572, A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 152,  A_WK: 0,    P_CUM: 19725, A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 19725, A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 19725, A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 19725, A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Piping',                 P_WK: 0,    A_WK: 0,    P_CUM: 19725, A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 199,  A_WK: 16,   P_CUM: 199,   A_CUM: 16, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 344,  A_WK: 0,    P_CUM: 543,   A_CUM: 16, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 494,  A_WK: 0,    P_CUM: 1038,  A_CUM: 16, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 850,  A_WK: 0,    P_CUM: 1888,  A_CUM: 16, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1309, A_WK: 0,    P_CUM: 3197,  A_CUM: 16, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1268, A_WK: 0,    P_CUM: 4466,  A_CUM: 16, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1752, A_WK: 0,    P_CUM: 6218,  A_CUM: 16, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1958, A_WK: 0,    P_CUM: 8177,  A_CUM: 16, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 2380, A_WK: 0,    P_CUM: 10557, A_CUM: 16, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1457, A_WK: 0,    P_CUM: 12015, A_CUM: 16, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 2000, A_WK: 0,    P_CUM: 14016, A_CUM: 16, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1549, A_WK: 0,    P_CUM: 15565, A_CUM: 16, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1966, A_WK: 0,    P_CUM: 17532, A_CUM: 16, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1541, A_WK: 0,    P_CUM: 19073, A_CUM: 16, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 2066, A_WK: 0,    P_CUM: 21140, A_CUM: 16, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1166, A_WK: 0,    P_CUM: 22306, A_CUM: 16, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 1079, A_WK: 0,    P_CUM: 23385, A_CUM: 16, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 515,  A_WK: 0,    P_CUM: 23901, A_CUM: 16, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 279,  A_WK: 0,    P_CUM: 24181, A_CUM: 16, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 202,  A_WK: 0,    P_CUM: 24383, A_CUM: 16, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 91,   A_WK: 0,    P_CUM: 24474, A_CUM: 16, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 63,   A_WK: 0,    P_CUM: 24538, A_CUM: 16, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 67,   A_WK: 0,    P_CUM: 24605, A_CUM: 16, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 38,   A_WK: 0,    P_CUM: 24643, A_CUM: 16, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 20,   A_WK: 0,    P_CUM: 24664, A_CUM: 16, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 40,   A_WK: 0,    P_CUM: 24705, A_CUM: 16, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 14,   A_WK: 0,    P_CUM: 24719, A_CUM: 16, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 64,   A_WK: 0,    P_CUM: 24783, A_CUM: 16, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 115,  A_WK: 0,    P_CUM: 24898, A_CUM: 16, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 24898, A_CUM: 16, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 24898, A_CUM: 16, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'HHI',          DISC: 'Structure',              P_WK: 0,    A_WK: 0,    P_CUM: 24898, A_CUM: 16, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 96,   P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'ShinHwaTech.', DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 96, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 32,   P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Construction',  GROUP: 'Construction',   CONT: 'Sung-II',      DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 32, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 4,    A_WK: 1,    P_CUM: 4,     A_CUM: 1, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 5,    A_WK: 5,    P_CUM: 9,     A_CUM: 6, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 4,    A_WK: 6,    P_CUM: 13,    A_CUM: 12, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 10,   A_WK: 8,    P_CUM: 23,    A_CUM: 20, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 8,    A_WK: 8,    P_CUM: 31,    A_CUM: 28, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 8,    A_WK: 7,    P_CUM: 39,    A_CUM: 35, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 10,   A_WK: 10,   P_CUM: 49,    A_CUM: 45, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 8,    A_WK: 8,    P_CUM: 57,    A_CUM: 53, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 8,    A_WK: 8,    P_CUM: 65,    A_CUM: 61, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 15,   A_WK: 6,    P_CUM: 80,    A_CUM: 67, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 12,   A_WK: 0,    P_CUM: 92,    A_CUM: 67, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 40,   A_WK: 0,    P_CUM: 132,   A_CUM: 67, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 32,   A_WK: 0,    P_CUM: 164,   A_CUM: 67, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 32,   A_WK: 0,    P_CUM: 196,   A_CUM: 67, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 32,   A_WK: 0,    P_CUM: 228,   A_CUM: 67, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 40,   A_WK: 0,    P_CUM: 268,   A_CUM: 67, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 36,   A_WK: 0,    P_CUM: 304,   A_CUM: 67, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 36,   A_WK: 0,    P_CUM: 340,   A_CUM: 67, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 45,   A_WK: 0,    P_CUM: 385,   A_CUM: 67, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 36,   A_WK: 0,    P_CUM: 421,   A_CUM: 67, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 50,   A_WK: 0,    P_CUM: 471,   A_CUM: 67, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 40,   A_WK: 0,    P_CUM: 511,   A_CUM: 67, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 40,   A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'Gate',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 551,   A_CUM: 67, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 3,    A_WK: 1,    P_CUM: 3,     A_CUM: 1, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 4,    A_WK: 2,    P_CUM: 7,     A_CUM: 4, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 4,    A_WK: 5,    P_CUM: 11,    A_CUM: 9, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 5,    A_WK: 2,    P_CUM: 16,    A_CUM: 12, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 4,    A_WK: 3,    P_CUM: 20,    A_CUM: 15, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 4,    A_WK: 3,    P_CUM: 24,    A_CUM: 19, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 10,   A_WK: 3,    P_CUM: 34,    A_CUM: 22, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 8,    A_WK: 0,    P_CUM: 42,    A_CUM: 22, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 10,   A_WK: 0,    P_CUM: 52,    A_CUM: 22, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 8,    A_WK: 0,    P_CUM: 60,    A_CUM: 22, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 8,    A_WK: 0,    P_CUM: 68,    A_CUM: 22, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 12,   A_WK: 0,    P_CUM: 80,    A_CUM: 22, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 15,   A_WK: 0,    P_CUM: 95,    A_CUM: 22, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 12,   A_WK: 0,    P_CUM: 107,   A_CUM: 22, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 12,   A_WK: 0,    P_CUM: 119,   A_CUM: 22, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 15,   A_WK: 0,    P_CUM: 134,   A_CUM: 22, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 12,   A_WK: 0,    P_CUM: 146,   A_CUM: 22, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 15,   A_WK: 0,    P_CUM: 161,   A_CUM: 22, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 12,   A_WK: 0,    P_CUM: 173,   A_CUM: 22, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 12,   A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Engineering',   GROUP: 'Commissing Eng', CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 185,   A_CUM: 22, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 166,  A_WK: 166,  P_CUM: 166,   A_CUM: 166, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 196,  A_WK: 195,  P_CUM: 363,   A_CUM: 362, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 444,  A_WK: 276,  P_CUM: 807,   A_CUM: 639, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 367,  A_WK: 264,  P_CUM: 1175,  A_CUM: 903, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 350,  A_WK: 266,  P_CUM: 1526,  A_CUM: 1170, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 440,  A_WK: 345,  P_CUM: 1966,  A_CUM: 1516, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 419,  A_WK: 328,  P_CUM: 2386,  A_CUM: 1844, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 407,  A_WK: 392,  P_CUM: 2794,  A_CUM: 2236, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 440,  A_WK: 483,  P_CUM: 3234,  A_CUM: 2720, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 353,  A_WK: 366,  P_CUM: 3587,  A_CUM: 3087, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 387,  A_WK: 361,  P_CUM: 3975,  A_CUM: 3449, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 211,  A_WK: 275,  P_CUM: 4187,  A_CUM: 3724, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 169,  A_WK: 229,  P_CUM: 4356,  A_CUM: 3954, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 179,  A_WK: 286,  P_CUM: 4536,  A_CUM: 4241, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 125,  A_WK: 227,  P_CUM: 4661,  A_CUM: 4468, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 89,   A_WK: 205,  P_CUM: 4751,  A_CUM: 4674, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 163,  A_WK: 135,  P_CUM: 4914,  A_CUM: 4809, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 116,  A_WK: 0,    P_CUM: 5030,  A_CUM: 4809, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 53,   A_WK: 0,    P_CUM: 5083,  A_CUM: 4809, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 18,   A_WK: 0,    P_CUM: 5102,  A_CUM: 4809, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 12,   A_WK: 0,    P_CUM: 5115,  A_CUM: 4809, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 8,    A_WK: 0,    P_CUM: 5123,  A_CUM: 4809, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 5,    A_WK: 0,    P_CUM: 5128,  A_CUM: 4809, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5132,  A_CUM: 4809, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5137,  A_CUM: 4809, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 5,    A_WK: 0,    P_CUM: 5142,  A_CUM: 4809, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5146,  A_CUM: 4809, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 5,    A_WK: 0,    P_CUM: 5151,  A_CUM: 4809, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5155,  A_CUM: 4809, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5160,  A_CUM: 4809, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 5,    A_WK: 0,    P_CUM: 5165,  A_CUM: 4809, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5169,  A_CUM: 4809, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5173,  A_CUM: 4809, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5177,  A_CUM: 4809, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 5,    A_WK: 0,    P_CUM: 5183,  A_CUM: 4809, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5187,  A_CUM: 4809, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 5,    A_WK: 0,    P_CUM: 5192,  A_CUM: 4809, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5196,  A_CUM: 4809, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5201,  A_CUM: 4809, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 5,    A_WK: 0,    P_CUM: 5206,  A_CUM: 4809, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5210,  A_CUM: 4809, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5214,  A_CUM: 4809, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 5,    A_WK: 0,    P_CUM: 5219,  A_CUM: 4809, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5224,  A_CUM: 4809, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 5228,  A_CUM: 4809, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 5228,  A_CUM: 4809, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 5228,  A_CUM: 4809, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'Exmar',        DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 5228,  A_CUM: 4809, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 179,  A_WK: 110,  P_CUM: 179,   A_CUM: 110, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 178,  A_WK: 109,  P_CUM: 357,   A_CUM: 220, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 283,  A_WK: 259,  P_CUM: 640,   A_CUM: 479, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 248,  A_WK: 231,  P_CUM: 888,   A_CUM: 711, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 260,  A_WK: 231,  P_CUM: 1148,  A_CUM: 942, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 346,  A_WK: 330,  P_CUM: 1494,  A_CUM: 1273, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 320,  A_WK: 303,  P_CUM: 1814,  A_CUM: 1576, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 348,  A_WK: 327,  P_CUM: 2162,  A_CUM: 1903, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 495,  A_WK: 464,  P_CUM: 2657,  A_CUM: 2367, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 464,  A_WK: 497,  P_CUM: 3121,  A_CUM: 2865, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 606,  A_WK: 692,  P_CUM: 3727,  A_CUM: 3557, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 533,  A_WK: 658,  P_CUM: 4260,  A_CUM: 4215, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 681,  A_WK: 702,  P_CUM: 4941,  A_CUM: 4918, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 735,  A_WK: 701,  P_CUM: 5677,  A_CUM: 5619, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 660,  A_WK: 636,  P_CUM: 6337,  A_CUM: 6255, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 586,  A_WK: 619,  P_CUM: 6924,  A_CUM: 6875, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 633,  A_WK: 443,  P_CUM: 7558,  A_CUM: 7318, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 458,  A_WK: 0,    P_CUM: 8016,  A_CUM: 7318, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 504,  A_WK: 0,    P_CUM: 8520,  A_CUM: 7318, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 352,  A_WK: 0,    P_CUM: 8873,  A_CUM: 7318, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 243,  A_WK: 0,    P_CUM: 9116,  A_CUM: 7318, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 195,  A_WK: 0,    P_CUM: 9312,  A_CUM: 7318, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 148,  A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 9460,  A_CUM: 7318, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 302,  A_WK: 305,  P_CUM: 302,   A_CUM: 305, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 492,  A_WK: 450,  P_CUM: 795,   A_CUM: 756, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 796,  A_WK: 660,  P_CUM: 1591,  A_CUM: 1416, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 714,  A_WK: 585,  P_CUM: 2306,  A_CUM: 2001, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 761,  A_WK: 599,  P_CUM: 3068,  A_CUM: 2601, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 1001, A_WK: 778,  P_CUM: 4069,  A_CUM: 3380, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 839,  A_WK: 721,  P_CUM: 4909,  A_CUM: 4101, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 843,  A_WK: 881,  P_CUM: 5752,  A_CUM: 4982, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 1056, A_WK: 1075, P_CUM: 6808,  A_CUM: 6058, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 858,  A_WK: 938,  P_CUM: 7666,  A_CUM: 6996, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 1084, A_WK: 1143, P_CUM: 8751,  A_CUM: 8140, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 866,  A_WK: 953,  P_CUM: 9618,  A_CUM: 9093, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 849,  A_WK: 947,  P_CUM: 10467, A_CUM: 10041, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 1026, A_WK: 1301, P_CUM: 11494, A_CUM: 11342, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 777,  A_WK: 1062, P_CUM: 12271, A_CUM: 12404, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 735,  A_WK: 1057, P_CUM: 13006, A_CUM: 13462, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 1094, A_WK: 799,  P_CUM: 14100, A_CUM: 14262, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 819,  A_WK: 0,    P_CUM: 14920, A_CUM: 14262, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 878,  A_WK: 0,    P_CUM: 15799, A_CUM: 14262, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 662,  A_WK: 0,    P_CUM: 16461, A_CUM: 14262, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 527,  A_WK: 0,    P_CUM: 16989, A_CUM: 14262, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 431,  A_WK: 0,    P_CUM: 17420, A_CUM: 14262, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 252,  A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Engineering',   GROUP: 'Detail Eng',     CONT: 'WOOD',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 17673, A_CUM: 14262, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 25,   A_WK: 0,    P_CUM: 25,    A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 186,  A_WK: 0,    P_CUM: 211,   A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 315,  A_WK: 44,   P_CUM: 527,   A_CUM: 44, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 825,  A_WK: 58,   P_CUM: 1352,  A_CUM: 102, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 812,  A_WK: 105,  P_CUM: 2164,  A_CUM: 208, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 373,  A_WK: 0,    P_CUM: 2538,  A_CUM: 208, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 849,  A_WK: 0,    P_CUM: 3387,  A_CUM: 208, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 986,  A_WK: 0,    P_CUM: 4374,  A_CUM: 208, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 1436, A_WK: 0,    P_CUM: 5810,  A_CUM: 208, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 1215, A_WK: 0,    P_CUM: 7026,  A_CUM: 208, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 1750, A_WK: 0,    P_CUM: 8776,  A_CUM: 208, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 1094, A_WK: 0,    P_CUM: 9871,  A_CUM: 208, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 1058, A_WK: 0,    P_CUM: 10929, A_CUM: 208, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 1231, A_WK: 0,    P_CUM: 12161, A_CUM: 208, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 641,  A_WK: 0,    P_CUM: 12802, A_CUM: 208, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 468,  A_WK: 0,    P_CUM: 13270, A_CUM: 208, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 294,  A_WK: 0,    P_CUM: 13565, A_CUM: 208, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 57,   A_WK: 0,    P_CUM: 13622, A_CUM: 208, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 35,   A_WK: 0,    P_CUM: 13658, A_CUM: 208, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 74,   A_WK: 0,    P_CUM: 13732, A_CUM: 208, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 24,   A_WK: 0,    P_CUM: 13756, A_CUM: 208, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 155,  A_WK: 0,    P_CUM: 13911, A_CUM: 208, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 13,   A_WK: 0,    P_CUM: 13925, A_CUM: 208, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 4,    A_WK: 0,    P_CUM: 13929, A_CUM: 208, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 1,    A_WK: 0,    P_CUM: 13931, A_CUM: 208, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 13931, A_CUM: 208, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 10,   A_WK: 0,    P_CUM: 13941, A_CUM: 208, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 31,   A_WK: 0,    P_CUM: 13972, A_CUM: 208, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 33,   A_WK: 0,    P_CUM: 14005, A_CUM: 208, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 17,   A_WK: 0,    P_CUM: 14023, A_CUM: 208, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 26,   A_WK: 0,    P_CUM: 14050, A_CUM: 208, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 17,   A_WK: 0,    P_CUM: 14067, A_CUM: 208, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 14067, A_CUM: 208, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 14067, A_CUM: 208, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 14067, A_CUM: 208, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Engineering',   GROUP: 'Shop Drawing',   CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 14067, A_CUM: 208, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'Etc.',         DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 0,     A_CUM: 0, },
      { CDATE: '2023-07-28', MONTH: '2023-07-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 57,   A_WK: 40,   P_CUM: 57,    A_CUM: 40, },
      { CDATE: '2023-08-25', MONTH: '2023-08-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 76,   A_WK: 63,   P_CUM: 133,   A_CUM: 103, },
      { CDATE: '2023-09-29', MONTH: '2023-09-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 122,  A_WK: 108,  P_CUM: 256,   A_CUM: 211, },
      { CDATE: '2023-10-27', MONTH: '2023-10-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 112,  A_WK: 114,  P_CUM: 369,   A_CUM: 326, },
      { CDATE: '2023-11-24', MONTH: '2023-11-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 115,  A_WK: 117,  P_CUM: 484,   A_CUM: 443, },
      { CDATE: '2023-12-29', MONTH: '2023-12-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 164,  A_WK: 150,  P_CUM: 649,   A_CUM: 594, },
      { CDATE: '2024-01-26', MONTH: '2024-01-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 135,  A_WK: 151,  P_CUM: 784,   A_CUM: 745, },
      { CDATE: '2024-02-23', MONTH: '2024-02-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 135,  A_WK: 176,  P_CUM: 919,   A_CUM: 922, },
      { CDATE: '2024-03-29', MONTH: '2024-03-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 168,  A_WK: 234,  P_CUM: 1087,  A_CUM: 1156, },
      { CDATE: '2024-04-26', MONTH: '2024-04-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 135,  A_WK: 198,  P_CUM: 1222,  A_CUM: 1354, },
      { CDATE: '2024-05-31', MONTH: '2024-05-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 168,  A_WK: 256,  P_CUM: 1391,  A_CUM: 1611, },
      { CDATE: '2024-06-28', MONTH: '2024-06-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 168,  A_WK: 207,  P_CUM: 1560,  A_CUM: 1818, },
      { CDATE: '2024-07-26', MONTH: '2024-07-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 180,  A_WK: 213,  P_CUM: 1740,  A_CUM: 2031, },
      { CDATE: '2024-08-30', MONTH: '2024-08-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 226,  P_CUM: 1965,  A_CUM: 2257, },
      { CDATE: '2024-09-27', MONTH: '2024-09-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 180,  A_WK: 210,  P_CUM: 2145,  A_CUM: 2468, },
      { CDATE: '2024-10-25', MONTH: '2024-10-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 180,  A_WK: 217,  P_CUM: 2325,  A_CUM: 2685, },
      { CDATE: '2024-11-29', MONTH: '2024-11-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 168,  P_CUM: 2550,  A_CUM: 2854, },
      { CDATE: '2024-12-27', MONTH: '2024-12-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 213,  A_WK: 0,    P_CUM: 2764,  A_CUM: 2854, },
      { CDATE: '2025-01-31', MONTH: '2025-01-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 281,  A_WK: 0,    P_CUM: 3045,  A_CUM: 2854, },
      { CDATE: '2025-02-28', MONTH: '2025-02-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 3270,  A_CUM: 2854, },
      { CDATE: '2025-03-28', MONTH: '2025-03-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 3495,  A_CUM: 2854, },
      { CDATE: '2025-04-25', MONTH: '2025-04-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 3720,  A_CUM: 2854, },
      { CDATE: '2025-05-30', MONTH: '2025-05-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 281,  A_WK: 0,    P_CUM: 4001,  A_CUM: 2854, },
      { CDATE: '2025-06-27', MONTH: '2025-06-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 4226,  A_CUM: 2854, },
      { CDATE: '2025-07-25', MONTH: '2025-07-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 4451,  A_CUM: 2854, },
      { CDATE: '2025-08-29', MONTH: '2025-08-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 281,  A_WK: 0,    P_CUM: 4732,  A_CUM: 2854, },
      { CDATE: '2025-09-26', MONTH: '2025-09-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 4957,  A_CUM: 2854, },
      { CDATE: '2025-10-31', MONTH: '2025-10-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 281,  A_WK: 0,    P_CUM: 5239,  A_CUM: 2854, },
      { CDATE: '2025-11-28', MONTH: '2025-11-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 5464,  A_CUM: 2854, },
      { CDATE: '2025-12-26', MONTH: '2025-12-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 191,  A_WK: 0,    P_CUM: 5655,  A_CUM: 2854, },
      { CDATE: '2026-01-30', MONTH: '2026-01-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 5880,  A_CUM: 2854, },
      { CDATE: '2026-02-27', MONTH: '2026-02-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 180,  A_WK: 0,    P_CUM: 6060,  A_CUM: 2854, },
      { CDATE: '2026-03-27', MONTH: '2026-03-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 180,  A_WK: 0,    P_CUM: 6240,  A_CUM: 2854, },
      { CDATE: '2026-04-24', MONTH: '2026-04-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 180,  A_WK: 0,    P_CUM: 6420,  A_CUM: 2854, },
      { CDATE: '2026-05-29', MONTH: '2026-05-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 225,  A_WK: 0,    P_CUM: 6645,  A_CUM: 2854, },
      { CDATE: '2026-06-26', MONTH: '2026-06-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 180,  A_WK: 0,    P_CUM: 6825,  A_CUM: 2854, },
      { CDATE: '2026-07-31', MONTH: '2026-07-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 191,  A_WK: 0,    P_CUM: 7016,  A_CUM: 2854, },
      { CDATE: '2026-08-28', MONTH: '2026-08-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 135,  A_WK: 0,    P_CUM: 7151,  A_CUM: 2854, },
      { CDATE: '2026-09-25', MONTH: '2026-09-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 135,  A_WK: 0,    P_CUM: 7286,  A_CUM: 2854, },
      { CDATE: '2026-10-30', MONTH: '2026-10-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 168,  A_WK: 0,    P_CUM: 7455,  A_CUM: 2854, },
      { CDATE: '2026-11-27', MONTH: '2026-11-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 135,  A_WK: 0,    P_CUM: 7590,  A_CUM: 2854, },
      { CDATE: '2026-12-25', MONTH: '2026-12-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 135,  A_WK: 0,    P_CUM: 7725,  A_CUM: 2854, },
      { CDATE: '2027-01-29', MONTH: '2027-01-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 135,  A_WK: 0,    P_CUM: 7860,  A_CUM: 2854, },
      { CDATE: '2027-02-26', MONTH: '2027-02-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 90,   A_WK: 0,    P_CUM: 7950,  A_CUM: 2854, },
      { CDATE: '2027-03-26', MONTH: '2027-03-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 90,   A_WK: 0,    P_CUM: 8040,  A_CUM: 2854, },
      { CDATE: '2027-04-30', MONTH: '2027-04-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 8040,  A_CUM: 2854, },
      { CDATE: '2027-05-28', MONTH: '2027-05-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 27,   A_WK: 0,    P_CUM: 8067,  A_CUM: 2854, },
      { CDATE: '2027-06-11', MONTH: '2027-06-01', PHASE: 'Management',    GROUP: 'Management',     CONT: 'HHI',          DISC: 'ALL',                    P_WK: 0,    A_WK: 0,    P_CUM: 8067,  A_CUM: 2854, },
      
    ],


    Queries: {
      SQL1: [
        {
          // Timeline
          CUTOFF      : '2024-11-15',
          START_DATE  : '2023-06-30',
          END_DATE    : '2027-07-02',
        },
      ],



    },


}