import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 500,
      CanvasBorderWeight: .5,
      CanvasBorderColor: '#E0E0DF',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 400,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Text: {
      
    },

    
    DataItems: [
      { CDATE: '2024-07-05', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-12', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 0,    PF_CUM: 0,    AS_CUM: 0,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-19', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 0,   AS_WK: 1,  AF_WK: 0,  PS_CUM: 1,    PF_CUM: 0,    AS_CUM: 1,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-07-26', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 7,   PF_WK: 0,   AS_WK: 7,  AF_WK: 0,  PS_CUM: 8,    PF_CUM: 0,    AS_CUM: 8,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-02', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 8,    PF_CUM: 0,    AS_CUM: 8,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-09', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 8,    PF_CUM: 0,    AS_CUM: 8,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-16', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 0,   AS_WK: 3,  AF_WK: 0,  PS_CUM: 11,   PF_CUM: 0,    AS_CUM: 11,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-23', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 0,   AS_WK: 2,  AF_WK: 0,  PS_CUM: 13,   PF_CUM: 0,    AS_CUM: 13,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-08-30', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 13,   PF_CUM: 0,    AS_CUM: 13,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-06', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 6,   PF_WK: 0,   AS_WK: 7,  AF_WK: 0,  PS_CUM: 19,   PF_CUM: 0,    AS_CUM: 20,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-13', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 5,   PF_WK: 0,   AS_WK: 4,  AF_WK: 0,  PS_CUM: 24,   PF_CUM: 0,    AS_CUM: 24,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-20', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 6,   PF_WK: 0,   AS_WK: 6,  AF_WK: 0,  PS_CUM: 30,   PF_CUM: 0,    AS_CUM: 30,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-09-27', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 7,   PF_WK: 0,   AS_WK: 11, AF_WK: 0,  PS_CUM: 37,   PF_CUM: 0,    AS_CUM: 41,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-04', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 6,   PF_WK: 0,   AS_WK: 3,  AF_WK: 0,  PS_CUM: 43,   PF_CUM: 0,    AS_CUM: 44,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-11', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 4,   PF_WK: 0,   AS_WK: 7,  AF_WK: 0,  PS_CUM: 47,   PF_CUM: 0,    AS_CUM: 51,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-18', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 10,  PF_WK: 5,   AS_WK: 13, AF_WK: 8,  PS_CUM: 57,   PF_CUM: 5,    AS_CUM: 64,  AF_CUM: 8,   DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-10-25', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 16,  PF_WK: 10,  AS_WK: 15, AF_WK: 9,  PS_CUM: 73,   PF_CUM: 15,   AS_CUM: 79,  AF_CUM: 17,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-01', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 17,  PF_WK: 10,  AS_WK: 36, AF_WK: 28, PS_CUM: 90,   PF_CUM: 25,   AS_CUM: 115, AF_CUM: 45,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-08', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 30,  PF_WK: 7,   AS_WK: 35, AF_WK: 7,  PS_CUM: 120,  PF_CUM: 32,   AS_CUM: 150, AF_CUM: 52,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-15', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 41,  PF_WK: 6,   AS_WK: 34, AF_WK: 5,  PS_CUM: 161,  PF_CUM: 38,   AS_CUM: 184, AF_CUM: 57,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-22', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 78,  PF_WK: 7,   AS_WK: 92, AF_WK: 11, PS_CUM: 239,  PF_CUM: 45,   AS_CUM: 276, AF_CUM: 68,  DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-11-29', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 83,  PF_WK: 33,  AS_WK: 79, AF_WK: 36, PS_CUM: 322,  PF_CUM: 78,   AS_CUM: 355, AF_CUM: 104, DS: 33, DF: 26, OS: 0, OF: 3, },
      { CDATE: '2024-12-06', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 80,  PF_WK: 24,  AS_WK: 7,  AF_WK: 0,  PS_CUM: 402,  PF_CUM: 102,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-13', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 99,  PF_WK: 39,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 501,  PF_CUM: 141,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-20', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 130, PF_WK: 37,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 631,  PF_CUM: 178,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2024-12-27', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 93,  PF_WK: 46,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 724,  PF_CUM: 224,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-03', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 104, PF_WK: 65,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 828,  PF_CUM: 289,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-10', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 167, PF_WK: 82,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 995,  PF_CUM: 371,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-17', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 192, PF_WK: 69,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 1187, PF_CUM: 440,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-24', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 158, PF_WK: 156, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1345, PF_CUM: 596,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-01-31', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 272, PF_WK: 108, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1617, PF_CUM: 704,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-07', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 181, PF_WK: 133, AS_WK: 0,  AF_WK: 0,  PS_CUM: 1798, PF_CUM: 837,  AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-14', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 213, PF_WK: 208, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2011, PF_CUM: 1045, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-21', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 282, PF_WK: 167, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2293, PF_CUM: 1212, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-02-28', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 295, PF_WK: 170, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2588, PF_CUM: 1382, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-07', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 270, PF_WK: 163, AS_WK: 0,  AF_WK: 0,  PS_CUM: 2858, PF_CUM: 1545, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-14', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 231, PF_WK: 210, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3089, PF_CUM: 1755, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-21', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 221, PF_WK: 245, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3310, PF_CUM: 2000, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-03-28', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 231, PF_WK: 316, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3541, PF_CUM: 2316, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-04', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 220, PF_WK: 237, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3761, PF_CUM: 2553, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-11', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 209, PF_WK: 252, AS_WK: 0,  AF_WK: 0,  PS_CUM: 3970, PF_CUM: 2805, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-18', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 238, PF_WK: 226, AS_WK: 0,  AF_WK: 0,  PS_CUM: 4208, PF_CUM: 3031, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-04-25', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 167, PF_WK: 172, AS_WK: 0,  AF_WK: 0,  PS_CUM: 4375, PF_CUM: 3203, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-02', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 184, PF_WK: 307, AS_WK: 0,  AF_WK: 0,  PS_CUM: 4559, PF_CUM: 3510, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-09', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 132, PF_WK: 231, AS_WK: 0,  AF_WK: 0,  PS_CUM: 4691, PF_CUM: 3741, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-16', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 170, PF_WK: 196, AS_WK: 0,  AF_WK: 0,  PS_CUM: 4861, PF_CUM: 3937, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-23', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 137, PF_WK: 237, AS_WK: 0,  AF_WK: 0,  PS_CUM: 4998, PF_CUM: 4174, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-05-30', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 180, PF_WK: 185, AS_WK: 0,  AF_WK: 0,  PS_CUM: 5178, PF_CUM: 4359, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-06', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 177, PF_WK: 173, AS_WK: 0,  AF_WK: 0,  PS_CUM: 5355, PF_CUM: 4532, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-13', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 168, PF_WK: 153, AS_WK: 0,  AF_WK: 0,  PS_CUM: 5523, PF_CUM: 4685, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-20', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 178, PF_WK: 113, AS_WK: 0,  AF_WK: 0,  PS_CUM: 5701, PF_CUM: 4798, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-06-27', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 133, PF_WK: 158, AS_WK: 0,  AF_WK: 0,  PS_CUM: 5834, PF_CUM: 4956, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-04', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 95,  PF_WK: 217, AS_WK: 0,  AF_WK: 0,  PS_CUM: 5929, PF_CUM: 5173, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-11', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 145, PF_WK: 160, AS_WK: 0,  AF_WK: 0,  PS_CUM: 6074, PF_CUM: 5333, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-18', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 145, PF_WK: 153, AS_WK: 0,  AF_WK: 0,  PS_CUM: 6219, PF_CUM: 5486, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-07-25', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 116, PF_WK: 100, AS_WK: 0,  AF_WK: 0,  PS_CUM: 6335, PF_CUM: 5586, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-01', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 160, PF_WK: 85,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 6495, PF_CUM: 5671, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-08', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 161, PF_WK: 178, AS_WK: 0,  AF_WK: 0,  PS_CUM: 6656, PF_CUM: 5849, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-15', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 146, PF_WK: 185, AS_WK: 0,  AF_WK: 0,  PS_CUM: 6802, PF_CUM: 6034, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-22', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 86,  PF_WK: 157, AS_WK: 0,  AF_WK: 0,  PS_CUM: 6888, PF_CUM: 6191, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-08-29', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 53,  PF_WK: 148, AS_WK: 0,  AF_WK: 0,  PS_CUM: 6941, PF_CUM: 6339, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-05', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 57,  PF_WK: 150, AS_WK: 0,  AF_WK: 0,  PS_CUM: 6998, PF_CUM: 6489, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-12', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 81,  PF_WK: 117, AS_WK: 0,  AF_WK: 0,  PS_CUM: 7079, PF_CUM: 6606, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-19', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 63,  PF_WK: 119, AS_WK: 0,  AF_WK: 0,  PS_CUM: 7142, PF_CUM: 6725, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-09-26', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 69,  PF_WK: 63,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7211, PF_CUM: 6788, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-03', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 49,  PF_WK: 51,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7260, PF_CUM: 6839, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-10', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 8,   PF_WK: 10,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7268, PF_CUM: 6849, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-17', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 26,  PF_WK: 175, AS_WK: 0,  AF_WK: 0,  PS_CUM: 7294, PF_CUM: 7024, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-24', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 16,  PF_WK: 103, AS_WK: 0,  AF_WK: 0,  PS_CUM: 7310, PF_CUM: 7127, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-10-31', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 24,  PF_WK: 50,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7334, PF_CUM: 7177, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-07', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 21,  PF_WK: 47,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7355, PF_CUM: 7224, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-14', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 27,  PF_WK: 38,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7382, PF_CUM: 7262, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-21', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 16,  PF_WK: 43,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7398, PF_CUM: 7305, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-11-28', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 7,   PF_WK: 35,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7405, PF_CUM: 7340, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-05', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 8,   PF_WK: 22,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7413, PF_CUM: 7362, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-12', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 6,   PF_WK: 19,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7419, PF_CUM: 7381, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-19', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 10,  PF_WK: 6,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7429, PF_CUM: 7387, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2025-12-26', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 7,   PF_WK: 7,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7436, PF_CUM: 7394, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-02', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 17,  PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7453, PF_CUM: 7399, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-09', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 7,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7456, PF_CUM: 7406, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-16', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 13,  PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7469, PF_CUM: 7410, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-23', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 4,   PF_WK: 8,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7473, PF_CUM: 7418, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-01-30', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 4,   PF_WK: 23,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7477, PF_CUM: 7441, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-06', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 6,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7483, PF_CUM: 7443, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-13', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 9,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7486, PF_CUM: 7452, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-20', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 8,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7494, PF_CUM: 7454, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-02-27', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 5,   PF_WK: 6,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7499, PF_CUM: 7460, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-06', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 16,  AS_WK: 0,  AF_WK: 0,  PS_CUM: 7499, PF_CUM: 7476, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-13', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 4,   PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7503, PF_CUM: 7480, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-20', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 8,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7505, PF_CUM: 7488, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-03-27', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7508, PF_CUM: 7489, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-03', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7509, PF_CUM: 7490, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-10', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7510, PF_CUM: 7492, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-17', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7513, PF_CUM: 7493, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-04-24', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 6,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7514, PF_CUM: 7499, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-01', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7516, PF_CUM: 7501, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-08', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7516, PF_CUM: 7503, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-15', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7517, PF_CUM: 7507, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-22', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7518, PF_CUM: 7508, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-05-29', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7518, PF_CUM: 7512, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-05', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7519, PF_CUM: 7512, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-12', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7521, PF_CUM: 7514, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-19', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7523, PF_CUM: 7516, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-06-26', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7523, PF_CUM: 7516, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-03', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7518, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-10', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7518, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-17', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7520, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-24', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7521, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-07-31', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7521, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-08-07', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7523, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-08-14', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7524, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-08-21', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7524, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-08-28', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7525, PF_CUM: 7525, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-09-04', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 8,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7533, PF_CUM: 7525, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-09-11', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 6,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7539, PF_CUM: 7525, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-09-18', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7541, PF_CUM: 7525, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-09-25', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7543, PF_CUM: 7525, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-10-02', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 8,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7551, PF_CUM: 7525, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-10-09', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 9,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7554, PF_CUM: 7534, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-10-16', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7555, PF_CUM: 7539, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-10-23', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 6,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7561, PF_CUM: 7539, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-10-30', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7564, PF_CUM: 7539, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-11-06', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7565, PF_CUM: 7539, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-11-13', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 6,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7567, PF_CUM: 7545, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-11-20', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7570, PF_CUM: 7548, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-11-27', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 9,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7570, PF_CUM: 7557, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-12-04', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 2,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7572, PF_CUM: 7559, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-12-11', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 3,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7575, PF_CUM: 7560, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-12-18', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 5,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7576, PF_CUM: 7565, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2026-12-25', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7577, PF_CUM: 7565, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-01-01', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7577, PF_CUM: 7566, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-01-08', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 7,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7577, PF_CUM: 7573, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-01-15', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 3,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7578, PF_CUM: 7576, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-01-22', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 2,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7580, PF_CUM: 7576, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-01-29', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7580, PF_CUM: 7576, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-02-05', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 1,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7581, PF_CUM: 7576, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-02-12', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 1,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7581, PF_CUM: 7577, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-02-19', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 4,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7581, PF_CUM: 7581, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-02-26', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7581, PF_CUM: 7581, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-03-05', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7581, PF_CUM: 7581, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-03-12', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7581, PF_CUM: 7581, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-03-19', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7581, PF_CUM: 7581, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
      { CDATE: '2027-03-26', AREA: 'OVERALL', DISC: 'OVERALL', TOT: 7581, PS_WK: 0,   PF_WK: 0,   AS_WK: 0,  AF_WK: 0,  PS_CUM: 7581, PF_CUM: 7581, AS_CUM: 362, AF_CUM: 104, DS: 0,  DF: 0,  OS: 0, OF: 0, },
    ],


    Queries: {
      
      SQL1: [
        {
          // Timeline
          CUTOFF      : '2024-11-29',
          START_DATE  : '2024-07-05',
          END_DATE    : '2026-07-31',
        },
      ],

      SQL2: [
        { AREA: 'LQ',      DISC: 'OVERALL',                 TOTAL: 847,  PS_CUM: 156, PF_CUM: 29, AS_CUM: 157, AF_CUM: 32,  DS: 1,  DF: 3,  OS: 0, OF: 0, PLAN: 7.9,  ACT: 8.2,  DEV: -0.3, },
        { AREA: 'Topside', DISC: 'OVERALL',                 TOTAL: 3729, PS_CUM: 10,  PF_CUM: 0,  AS_CUM: 10,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.1,  ACT: 0.1,  DEV: 0, },
        { AREA: 'Hull',    DISC: 'OVERALL',                 TOTAL: 3005, PS_CUM: 156, PF_CUM: 49, AS_CUM: 188, AF_CUM: 72,  DS: 32, DF: 23, OS: 0, OF: 3, PLAN: 5.3,  ACT: 5.6,  DEV: -0.3, },
        { AREA: 'OVERALL',   DISC: 'Architectural',         TOTAL: 89,   PS_CUM: 1,   PF_CUM: 0,  AS_CUM: 1,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0,    ACT: 0,    DEV: 0, },
        { AREA: 'OVERALL',   DISC: 'Construction',          TOTAL: 332,  PS_CUM: 32,  PF_CUM: 1,  AS_CUM: 35,  AF_CUM: 1,   DS: 3,  DF: 0,  OS: 0, OF: 0, PLAN: 2.9,  ACT: 3.2,  DEV: -0.4, },
        { AREA: 'OVERALL',   DISC: 'E&I',                   TOTAL: 2360, PS_CUM: 74,  PF_CUM: 2,  AS_CUM: 74,  AF_CUM: 2,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.2,  ACT: 0.2,  DEV: 0, },
        { AREA: 'OVERALL',   DISC: 'HVAC',                  TOTAL: 353,  PS_CUM: 23,  PF_CUM: 1,  AS_CUM: 23,  AF_CUM: 1,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.6,  ACT: 0.6,  DEV: 0, },
        { AREA: 'OVERALL',   DISC: 'Mechanical',            TOTAL: 47,   PS_CUM: 0,   PF_CUM: 0,  AS_CUM: 0,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0,    ACT: 0,    DEV: 0, },
        { AREA: 'OVERALL',   DISC: 'Outfitting',            TOTAL: 299,  PS_CUM: 6,   PF_CUM: 0,  AS_CUM: 6,   AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.1,  ACT: 0.1,  DEV: 0, },
        { AREA: 'OVERALL',   DISC: 'Piping',                TOTAL: 2745, PS_CUM: 40,  PF_CUM: 27, AS_CUM: 69,  AF_CUM: 53,  DS: 29, DF: 26, OS: 0, OF: 0, PLAN: 0.8,  ACT: 1.5,  DEV: -0.7, },
        { AREA: 'OVERALL',   DISC: 'Plumbing',              TOTAL: 116,  PS_CUM: 7,   PF_CUM: 2,  AS_CUM: 8,   AF_CUM: 5,   DS: 1,  DF: 3,  OS: 0, OF: 0, PLAN: 0.2,  ACT: 0.4,  DEV: -0.2, },
        { AREA: 'OVERALL',   DISC: 'Structural',            TOTAL: 1161, PS_CUM: 121, PF_CUM: 40, AS_CUM: 121, AF_CUM: 38,  DS: 0,  DF: -2, OS: 0, OF: 2, PLAN: 4.2,  ACT: 4,    DEV: 0.2, },
        { AREA: 'OVERALL',   DISC: 'Structural Outfitting', TOTAL: 79,   PS_CUM: 18,  PF_CUM: 5,  AS_CUM: 18,  AF_CUM: 4,   DS: 0,  DF: -1, OS: 0, OF: 1, PLAN: 11.3, ACT: 10.4, DEV: 0.9, },
        { AREA: 'OVERALL',   DISC: 'OVERALL',                 TOTAL: 7581, PS_CUM: 322, PF_CUM: 78, AS_CUM: 355, AF_CUM: 104, DS: 33, DF: 26, OS: 0, OF: 3, PLAN: 1.7,  ACT: 1.8,  DEV: -0.1, },
      ],

    },
}